export const prepareDeclarationOfAvailabilityUrl = (
  accessibilityPageEnabled,
  contextFriendlyUrl
) => {
  const domainName = window.location.href.replace(
    window.location.pathname,
    "/"
  );
  if (contextFriendlyUrl === "default" && accessibilityPageEnabled) {
    return domainName + "e,deklaracja.html";
  } else if (contextFriendlyUrl !== "default" && accessibilityPageEnabled) {
    return domainName + contextFriendlyUrl + ",e,deklaracja.html";
  }
  return null;
};
