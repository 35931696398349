import React from "react";

import { Form } from "../HookForm";
import { SearchField } from "./SearchField";
import { searchFormValidationSchema } from "./searchFormValidationSchema";

interface IProps {
  name: string;
  defaultValue: string;
  onSubmit: (model: any) => void;
}

const SearchForm = ({ name, defaultValue, onSubmit }: IProps) => {
  return (
    <Form
      defaultValues={{
        [name]: defaultValue
      }}
      onSubmit={onSubmit}
      validationSchema={searchFormValidationSchema(name)}
      resetOnSubmit={false}
    >
      {() => <SearchField name={name} />}
    </Form>
  );
};

export { SearchForm };
