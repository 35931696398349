import React from "react";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap as ReactGoogleMap,
  Marker
} from "react-google-maps";
import InfoWindow from "./InfoWindow";

const GoogleMap = ({
  mapConfiguration,
  openedMarkerId,
  onMarkerClick,
  onMarkerCloseClick
}) => {
  return (
    <ReactGoogleMap
      defaultZoom={mapConfiguration.zoom}
      defaultCenter={{
        lat: mapConfiguration.centerLat,
        lng: mapConfiguration.centerLng
      }}
    >
      {mapConfiguration.markers.map(marker => (
        <Marker
          key={marker.id}
          position={{ lat: marker.lat, lng: marker.lng }}
          onClick={() => onMarkerClick(marker.id)}
        >
          {openedMarkerId === marker.id && (
            <InfoWindow marker={marker} onCloseClick={onMarkerCloseClick} />
          )}
        </Marker>
      ))}
    </ReactGoogleMap>
  );
};

GoogleMap.propTypes = {
  mapConfiguration: PropTypes.shape({
    centerLat: PropTypes.number.isRequired,
    centerLng: PropTypes.number.isRequired,
    markers: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
        name: PropTypes.string,
        position: PropTypes.number
      })
    ),
    zoom: PropTypes.number.isRequired
  }),
  openedMarkerId: PropTypes.number,
  onMarkerClick: PropTypes.func.isRequired,
  onMarkerCloseClick: PropTypes.func.isRequired
};

const composed = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
);

export default composed(GoogleMap);
