import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { InputProps } from "react-autosuggest";
import { get } from "lodash";
import { ErrorMessage } from "@hookform/error-message";

import { StyledErrorMessage } from "../../../../../../HookForm";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "../../../../../../HookForm/Input";

interface IProps {
  inputProps: InputProps<string>;
}

const SuggestionInputField = ({ inputProps }: IProps) => {
  const { type, onChange: inputPropsOnChange, ...restInputProps } = inputProps;
  const methods = useFormContext();
  const hasError = Boolean(get(methods.errors, "s"));
  const isSubmitting = methods.formState.isSubmitting;

  return (
    <InputContainer>
      <Controller
        name="s"
        render={props => {
          const {
            onChange: controllerPropsOnChange,
            ...restControllerProps
          } = props;
          return (
            <>
              {/*eslint-disable-next-line*/}
              <Input
                accessKey="H"
                autoComplete="on"
                type={type}
                hasError={hasError}
                disabled={isSubmitting}
                {...restInputProps}
                {...restControllerProps}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  controllerPropsOnChange(event);
                  inputPropsOnChange(event, {
                    method: "type",
                    newValue: event.target.value
                  });
                }}
              />
            </>
          );
        }}
      />
      <ErrorMessage
        errors={methods.errors}
        name={"s"}
        render={({ message }) => {
          return (
            <StyledErrorMessage style={{ position: "absolute" }}>
              {message}
            </StyledErrorMessage>
          );
        }}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  & > input {
    display: block;
    width: 100%;
    color: ${({ theme }) => theme.text.primaryColor};
    border: 1px solid ${({ theme }) => theme.dividerColor};
    border-radius: 3px 0 0 3px;
    font-size: 1em;
    padding: 0.25rem 0.5rem;

    &::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
      cursor: pointer;
      padding: 0.125em;
    }
    &:focus:not(:active) {
      outline: ${({ theme }) => theme.focusOutline};
    }
  }
`;

export { SuggestionInputField };
