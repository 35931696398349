import styled from "styled-components";

export const DesktopAccessibilityButton = styled.button`
  font-weight: bold;
  color: ${({ theme }) => theme.text.primaryColor};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  min-width: 40px;
  font-size: 0.875em;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  border: solid 1px transparent;
  background-color: transparent;

  &:hover {
    border: solid 1px ${({ theme }) => theme.text.primaryColor};
  }
`;
