import React from "react";

import {useGetLocationParams} from "../../utils/useGetLocationParams";

import { IFormField } from "components/HookForm/types";
import { SearchForm } from "components/SearchForm/SearchForm";

import AdvancedSearch from "./components/advanced-search/AdvancedSearch";

interface IProps {
  fields: IFormField[];
  onSubmit: (model: any) => void;
}

const ContextSearch = ({ fields, onSubmit }: IProps) => {
  const parsedParams = useGetLocationParams();
  const contextSearchId = "cs-s";

  return (
    <>
      <SearchForm
        name={contextSearchId}
        defaultValue={parsedParams["form-cs-s"]?.toString() || ""}
        onSubmit={onSubmit}
      />
      <AdvancedSearch fields={fields} onSubmit={onSubmit} />
    </>
  );
};

export { ContextSearch };
