import React from "react";
import styled from "styled-components";

const RedStar = () => <StyledStar>*</StyledStar>;

const StyledStar = styled.span`
  color: red;
`;

export { RedStar };
