import { default as sanitizeHtmlLib } from "sanitize-html";

import { additionalAllowedTags } from "./additionalAllowedTags";

export const sanitizeHtml = (html: string) => {
  return sanitizeHtmlLib(html, {
    allowedAttributes: false,
    allowedTags: sanitizeHtmlLib.defaults.allowedTags.concat(
      additionalAllowedTags
    )
  });
};
