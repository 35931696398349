import React from "react";
import styled from "styled-components";

import { IMenuArticlesList } from "modules/articles/types/IMenuArticlesList";

import LinkButton from "components/buttons/LinkButton";

import { HideInPrintContent } from "./HideInPrintContent";
import { useGetIsOutdated } from "../utils/useGetIsOutdated";

interface IProps {
  menuArticles: IMenuArticlesList;
}

const ArchivedButtons = ({ menuArticles }: IProps) => {
  const isOutdated = useGetIsOutdated();

  return (
    <HideInPrintContent>
      {isOutdated ? (
        <StyledLinkButton
          to={`/${menuArticles.actualListLink}`}
          color="secondary"
        >
          Wróć do aktualnych
        </StyledLinkButton>
      ) : (
        <StyledLinkButton
          to={`/${menuArticles.outdatedListLink}`}
          color="secondary"
        >
          Pokaż archiwalne
        </StyledLinkButton>
      )}
    </HideInPrintContent>
  );
};

const StyledLinkButton = styled(LinkButton)`
  margin-bottom: 25px;
`;

export { ArchivedButtons };
