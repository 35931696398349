import React, { useState } from "react";
import styled from "styled-components";
import { Collapse } from "react-collapse";

import { Heading } from "./components/Heading";

import { IChildrenProp } from "types/IChildrenProp";

interface IProps extends IChildrenProp {
  id: string;
  title: string;
  printable?: boolean;
  isInitiallyOpen?: boolean;
  withCollapse?: boolean;
}

const Panel = ({
  children,
  id,
  title,
  printable = false,
  isInitiallyOpen = false,
  withCollapse = true
}: IProps) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const handleToggleIsOpen = () => {
    if (!withCollapse) {
      return;
    }
    setIsOpen(prevIsOpen => !prevIsOpen);
  };
  return (
    <Container id={`${id}-container`} printable={printable}>
      <Heading
        ariaControls={`${id}-content`}
        isOpen={isOpen}
        onClick={handleToggleIsOpen}
        title={title}
        withCollapse={withCollapse}
      />
      {withCollapse ? (
        <Collapse aria-hidden={!isOpen} id={`${id}-content`} isOpened={isOpen}>
          <NonPrintableContent>{children}</NonPrintableContent>
        </Collapse>
      ) : (
        <NonPrintableContent>{children}</NonPrintableContent>
      )}
      <PrintableContent>{children}</PrintableContent>
    </Container>
  );
};

const Container = styled.div`
  @media print {
    display: ${({ printable }) => (printable ? "block" : "none")};
  }
  margin-bottom: 30px;
`;

const Content = styled.div`
  padding: 25px 0 0 0;
`;

const PrintableContent = styled(Content)`
  display: none;
  @media print {
    display: block;
  }
`;

const NonPrintableContent = styled(Content)`
  height: auto;
  display: block;
  @media print {
    display: none;
  }
`;

export { Panel };
