import React from "react";

import { Row } from "../../TableComponents/MetricGrid";
import { ColumnWithTranslate } from "./ColumnWithTranslate";
import { ContentColumn } from "../ContentColumn";
import { SpanWithTranslation } from "./SpanWithTranslation";

interface IProps {
  createDate: string;
}

const CreationDate = ({ createDate }: IProps) => {
  return (
    <Row>
      <ColumnWithTranslate head>Creation date</ColumnWithTranslate>
      <ContentColumn>
        <SpanWithTranslation>{createDate}</SpanWithTranslation>
      </ContentColumn>
    </Row>
  );
};

export { CreationDate };
