import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { get } from "lodash";

import TableRow from "./TableRow";
import TableCell from "./TableCell";
import ColumnField from "./ColumnField";
import { mdiArrowRightBoldCircleOutline } from "@mdi/js";
import { Icon } from "../../../../../components/Icon";

const RowWithMore = ({ article, columns }) => {
  const articleTitle = get(article, "aliasFields[0].value");
  return (
    <TableRow>
      {columns.map((column, index) => (
        <Td key={column.id} width={column.width}>
          <ColumnField
            columnFields={column.columnFields}
            articleFields={article.columnFields}
          />
          {columns.length - 1 === index && (
            <Link
              to={`/${article.link}`}
              aria-label={`Przejdź do artykułu: ${articleTitle}`}
            >
              <StyledIcon
                path={mdiArrowRightBoldCircleOutline}
                title="Więcej"
                color="default"
                hoverAllowed={true}
                size="25px"
              />
            </Link>
          )}
        </Td>
      ))}
    </TableRow>
  );
};

const Td = TableCell.withComponent("td");

const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.text.secondaryColor};
  display: inline-block;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryColor};
  }
  float: right;
`;

const StyledIcon = styled(Icon)`
  margin-left: -17px;

  float: right;
`;

export { RowWithMore };
