import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import ApiConfig from "../../../../api/api.config";

const EmblemImage = () => {
  const { isContrast, logoFileId, siteTitle } = useMappedState(mapStateToProps);
  const logoAlt = `Logo urzędu ${siteTitle}`;
  const logoSrc = `${ApiConfig.getEntryPoint()}files/${logoFileId}`;

  return <>{isContrast ? siteTitle : <Image src={logoSrc} alt={logoAlt} />}</>;
};

const Image = styled.img`
  max-height: 80px;
  filter: ${({ theme }) => theme.imageFilter};
`;

const mapStateToProps = ({ accessibility, context }: any) => ({
  siteTitle: context.siteTitle,
  logoFileId: context.logoFileId,
  isContrast: accessibility.isContrast
});

export { EmblemImage };
