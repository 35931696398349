import React, {ReactNode} from "react";
import { useFormContext, UseFormMethods } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components";
import { get } from "lodash";

import Label from "./Label";
import { RedStar } from "./RedStar";
import Translate from "../translate";
import { StyledErrorMessage } from "./StyledErrorMessage";

import { ChildrenAsFunction } from "types/ChildrenAsFunction";

interface IProps {
  name: string;
  children: ChildrenAsFunction<{ methods: UseFormMethods; hasError: boolean }>;
  label?: ReactNode;
  isRequired?: boolean;
}

const FieldPrototype = ({ name, label, isRequired, children }: IProps) => {
  const methods = useFormContext();
  const hasError = Boolean(get(methods.errors, name));
  return (
    <FieldContainer>
      {label && (
        <Label htmlFor={name} hasError={hasError}>
          <Translate>{label}</Translate> {isRequired ? <RedStar /> : null}
        </Label>
      )}
      {children({ methods, hasError })}
      <ErrorMessage
        errors={methods.errors}
        name={name}
        render={({ message }) => {
          return <StyledErrorMessage>{message}</StyledErrorMessage>;
        }}
      />
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  margin-bottom: 15px;
`;

export { FieldPrototype };
