import React from "react";
import styled from "styled-components";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import moment from "moment";
import { mdiCalendar } from "@mdi/js";

import {Icon} from "../../Icon";

export interface IDatePickerInput
  extends Omit<ReactDatePickerProps, "onChange"> {
  name: string;
  hasError: boolean;
  value: string;
  handleChange: (date: Date) => void;
  lang?: string;
  label?: string;
  className?: string;
  isRequired?: boolean;
}

const DatePickerInput = ({
  isRequired = true,
  name,
  lang,
  label,
  className,
  handleChange,
  value,
  hasError,
  ...datePickerProps
}: IDatePickerInput) => {
  const selected = value && moment(value.toString()).toDate();

  return (
    <InputGroup>
      <DatePicker
        // @ts-ignore
        selected={selected}
        name={name}
        locale={lang}
        className={className || ""}
        dateFormat="yyyy-MM-dd"
        selectsEnd
        customInput={<Input hasError={hasError} type="text" value={value} />}
        showMonthDropdown
        showYearDropdown
        {...datePickerProps}
        onChange={handleChange}
      />
      <InputCalendar hasError={hasError}>
        <Icon path={mdiCalendar} title={"Kalendarz"} />
      </InputCalendar>
    </InputGroup>
  );
};

const InputGroup = styled.div`
  border-color: #0f6674 !important;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
`;

const Input = styled.input.attrs({ type: "text" })`
  color: ${({ theme }) => theme.text.primaryColor};
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.errorColor : theme.dividerColor};
  background: ${({ theme }) => theme.defaultBackground};
  font-size: 1em;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  border-radius: 3px 0 0 3px;
`;

const InputCalendar = styled.div`
  display: -ms-flexbox;
  display: flex;
  margin-left: -1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.8rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  && {
    font-size: 1em;
    color: ${({ theme }) => theme.text.primaryColor};
    background: ${({ theme }) => theme.lightPrimaryColor};
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.errorColor : theme.dividerColor};
    border-radius: 0 3px 3px 0;
  }
`;

export { DatePickerInput };
