import React, { useEffect, useState } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { useMappedState } from "redux-react-hook";
import useRouter from "use-react-router";

import { searchBarValidationSchema } from "modules/articles/schemas/searchBarValidationSchema";

import { ButtonWithCustomFocus } from "../../../../../buttons/index";
import { Form } from "../../../../../HookForm";
import { SearchSuggestions } from "./components/SearchSuggestions";

const SearchBar = () => {
  const { searchUrl } = useMappedState(mapStateToProps);
  const { location, history } = useRouter<{
    s: string;
  }>();

  const { s } = queryString.parse(location.search);
  const [searchValue, setSearchValue] = useState(s?.toString());

  const onSearch = (model: { s: string }) => {
    //Wyszukiwarka głowna z dodatkowymi polami nasłuchuje na query param ms
    const enhancedModel = {
      ...model,
      ms: ''
    }
    history.push(`/${searchUrl}?${queryString.stringify(enhancedModel)}`, {
      reload: true
    });
  };

  useEffect(() => {
    setSearchValue(s?.toString() || "");
  }, [s]);

  return (
    <StyledForm>
      <Form<{ s: string }>
        resetOnSubmit={false}
        defaultValues={{ s: searchValue }}
        onSubmit={onSearch}
        validationSchema={searchBarValidationSchema}
      >
        {() => {
          return (
            <SearchFieldGroup>
              <SearchSuggestions />
              <ButtonWithCustomFocus
                type="submit"
                color="primary"
                aria-label="Szukaj"
                title="Szukaj"
              >
                <span className="fa fa-search" />
              </ButtonWithCustomFocus>
            </SearchFieldGroup>
          );
        }}
      </Form>
    </StyledForm>
  );
};

const SearchFieldGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledForm = styled.div`

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 992px) {
    width: 40%;
  }

  @media screen and (min-width: 1200px) {
    width: 30%;
  }
`;

const mapStateToProps = ({ context }: any) => ({
  searchUrl: context.searchUrl
});

export { SearchBar };
