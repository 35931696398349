import React, { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import PropTypes from "prop-types";

const VideoPlayer = ({ url }) => {
  const ref = useRef(null);
  const [autoPlaying, setAutoPlaying] = useState(false);

  useEffect(() => {
    if (ref && ref.current && ref.current.offsetHeight > 0) {
      setAutoPlaying(true);
    }
  }, [ref]);

  return (
    <PlayerWrapper innerRef={ref}>
      <StyledReactPlayer
        id="VideoPlayer"
        className="react-player"
        url={url}
        width="100%"
        height="100%"
        controls={true}
        playing={autoPlaying}
      />
    </PlayerWrapper>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string
};

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export { VideoPlayer };
