/**
 * Created by fkurzawa on 22.03.18.
 */
import React from "react";
import styled from "styled-components";

import { prepareContent } from "./utils/prepareContent";

import { TipButton } from "./TipButton";

interface IProps {
  name: string;
  label: string;
  content: any;
}

const Section = ({ name, label, content }: IProps) => {
  return (
    <SectionContainer>
      <Header>
        <Name>{name}</Name>
        {label && <TipButton tip={label} />}
      </Header>
      <Content>{prepareContent(content)}</Content>
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  margin-bottom: 2rem;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.dividerColor};
`;

const Name = styled.h5`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.primaryColor};
  margin: 0;
  padding: 0;
`;

const Content = styled.div`
  color: ${({ theme }) => theme.text.primaryColor};
  font-size: 0.75em;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.dividerColor};

  a {
    color: ${({ theme }) => theme.text.secondaryColor};
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.secondaryColor};
      text-decoration: none;
    }
  }
`;

export { Section };
