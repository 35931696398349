import React, { ChangeEvent, ReactNode } from "react";
import { Controller } from "react-hook-form";

import { FieldPrototype } from "./FieldPrototype";
import { TextFieldType } from "./types/TextFieldType";
import { Input } from "./Input";

interface IProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  name: string;
  label?: ReactNode;
  isRequired?: boolean;
  type?: TextFieldType;
}

const TextField = ({
  isRequired = true,
  label,
  name,
  type = TextFieldType.TEXT,
  ...inputProps
}: IProps) => {
  return (
    <FieldPrototype isRequired={isRequired} label={label} name={name}>
      {({
        hasError,
        methods: {
          formState: { isSubmitting }
        }
      }) => (
        <Controller
          name={name}
          render={props => (
            <Input
              autoComplete="on"
              type={type}
              hasError={hasError}
              disabled={isSubmitting}
              {...inputProps}
              {...props}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                props.onChange(event);
                inputProps.onChange && inputProps.onChange(event);
              }}
            />
          )}
        />
      )}
    </FieldPrototype>
  );
};

export { TextField };
