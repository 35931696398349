import React from "react";

import { Column, Row } from "../../TableComponents/MetricGrid";
import { ColumnWithTranslate } from "./ColumnWithTranslate";
import { User } from "../../TableComponents/User";

import { IUser } from "types/IUser";

interface IProps {
  createUser: IUser;
}

const ProducerOfInformation = ({ createUser }: IProps) => {
  return (
    <Row>
      <ColumnWithTranslate head>
        The person who produced the information
      </ColumnWithTranslate>
      <Column>
        <User user={createUser} />
      </Column>
    </Row>
  );
};

export { ProducerOfInformation };
