import React from "react";

import { setFontSize } from "utils/font-size";
import { FONT_SIZES } from "constants/font_sizes";

import {DesktopAccessibilityButton} from "./DesktopAccessibilityButton";

const NormalLettersButton = () => {
  return (
    <>
      {/*eslint-disable-next-line*/}
      <DesktopAccessibilityButton
        title="Normalna wielkość liter"
        aria-label="Normalna wielkość liter"
        accessKey="J"
        onClick={() => setFontSize(FONT_SIZES.DEFAULT)}
      >
        A
      </DesktopAccessibilityButton>
    </>
  );
};

export { NormalLettersButton };
