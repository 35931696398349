import React from "react";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";

import { FormColumn } from "./FormColumn";
import { DateField } from "./DateField";
import { FormRow } from "./FormRow";
import { RedStar } from "./RedStar";
import Label from "./Label";

interface IProps {
  name: string;
  isRequired?: boolean;
  label?: string;
}

const DateRangeField = ({ name, label, isRequired = true }: IProps) => {
  const dateFromName = `${name}-from`;
  const dateToName = `${name}-to`;
  const { trigger, errors } = useFormContext();
  const hasError =
    Boolean(get(errors, dateFromName)) || Boolean(get(errors, dateToName));
  return (
    <FormRow>
      {label && (
        <Label htmlFor={name} hasError={hasError}>
          {label} {isRequired ? <RedStar /> : null}
        </Label>
      )}
      <FormColumn>
        <DateField
          isRequired={false}
          name={dateFromName}
          label="Od"
          onChange={() => trigger(dateToName)}
        />
      </FormColumn>
      <FormColumn>
        <DateField
          isRequired={false}
          name={dateToName}
          label="Do"
          onChange={() => trigger(dateFromName)}
        />
      </FormColumn>
    </FormRow>
  );
};

export { DateRangeField };
