import React from "react";
import { ParagraphSpacingButton } from "./Buttons/ParagraphSpacingButton";
import { LinesSpacingButton } from "./Buttons/LinesSpacingButton";
import { WordSpacingButton } from "./Buttons/WordSpacingButton";
import { LettersSpacingButton } from "./Buttons/LettersSpacingButton";
import { NormalLettersButton } from "./Buttons/NormalLettersButton";
import { MediumLettersButton } from "./Buttons/MediumLettersButton";
import { BigLettersButton } from "./Buttons/BigLettersButton";
import { ContrastButton } from "./Buttons/ContrastButton";
import styled from "styled-components";

const AccessibilityMenu = () => {
  return (
    <StyledAccessibilityMenu>
      <ParagraphSpacingButton />
      <LinesSpacingButton />
      <WordSpacingButton />
      <LettersSpacingButton />
      <NormalLettersButton />
      <MediumLettersButton />
      <BigLettersButton />
      <ContrastButton />
    </StyledAccessibilityMenu>
  );
};

const StyledAccessibilityMenu = styled.div`
  display: flex;
  margin-right: 25px;
`;

export { AccessibilityMenu };
