import React, {ReactNode} from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";

import Button from "../../buttons/Button";
import { Loader } from "../../NewLoader";

interface IProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: ReactNode;
  color?: string;
}

const SpinnerButton = ({ label, color = "primary", ...props }: IProps) => {
  const {
    formState: { isSubmitting },
    errors
  } = useFormContext();
  const hasErrors = !isEmpty(errors);
  return (
    <StyledButton
      type="submit"
      color={color}
      disabled={isSubmitting || hasErrors}
      {...props}
    >
      {isSubmitting ? <Loader size={20} /> : label}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { SpinnerButton };
