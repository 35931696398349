/**
 * Created by fkurzawa on 28.06.18.
 */
import { amber, white, grey, blueGrey, red } from "material-colors";
import backgroundImage from "./../assets/img/background-szamotuly.jpg";

const primaryTheme = {
  primaryColor: grey["500"],
  darkPrimaryColor: grey["700"],
  lightPrimaryColor: "#bccde5",
  secondaryColor: red["800"],
  dividerColor: grey["400"],
  layoutDividerColor: "#787878",
  errorColor: red["600"],
  highlightBackground: amber["100"],

  asideBackground:
    "linear-gradient(to bottom,#d6dbe7 0,#d6dbe7 66%,#9aacc5 100%)",
  asideMenuItemActiveBackground: "#dce3ed",
  asideMenuItemActiveColor: "#3b6094",
  asideMenuItemColor: "#3b6094",
  asideMenuItemBackground: "transparent",
  contentBackground: "#dce3ed",
  defaultBackground: white,
  layoutBackground: `url(${backgroundImage}) no-repeat center top #d7d7d7`,
  menuSeparatorBackground: "#012d6c",
  menuSeparatorColor: "white",
  pageTitleColor: "#0b9eca",
  toolbarBackground: "#d6dbe7",
  focusOutline: "#ff8c1a solid 2px",
  text: {
    primaryColor: blueGrey["800"],
    secondaryColor: blueGrey["600"],
    lightColor: blueGrey["200"]
  },

  buttons: {
    primary: {
      color: grey["50"],
      background: red["500"],
      hoverBackground: red["900"]
    },

    secondary: {
      color: grey["50"],
      background: blueGrey["500"],
      hoverBackground: blueGrey["900"]
    },

    default: {
      color: grey["800"],
      background: grey["300"],
      hoverBackground: grey["400"]
    },

    strongPrimary: {
      color: grey["50"],
      background: red["900"],
      hoverBackground: red["700"]
    },

    strongSecondary: {
      color: grey["50"],
      background: blueGrey["600"],
      hoverBackground: blueGrey["900"]
    }
  }
};

export default primaryTheme;
