import * as yup from "yup";
import { IForwardValues } from "./types/IForwardValues";

import { emailValidationSchema } from "utils/yup/emailValidationSchema";
import { validationMessages } from "utils/yup/messages";
import {recaptchaValidationSchema} from "utils/yup/recaptchaValidationSchema";

export const forwardValuesValidationSchema = yup
  .object<IForwardValues>()
  .shape({
    "recipient-email": emailValidationSchema.required(
      validationMessages.requiredField
    ),
    "referrer-email": emailValidationSchema.required(
      validationMessages.requiredField
    ),
    content: yup.string().required(validationMessages.requiredField),
    "recaptcha-response": recaptchaValidationSchema
  });
