import {getFileUrl} from "modules/articles/api/getFileUrl";

export const processImageSource = processNodeDefinitions => {
  return {
    shouldProcessNode: node =>
      node.type === "tag" && (node.name === "source" || node.name === "img"),
    processNode: (node, children, index) => {
      const { src } = node.attribs;
      const matchResult = src.match(/id(?:=|,)(\w+)(?:.json)?$/);
      if (matchResult !== null) {
        const [, id] = matchResult;
        const updatedNode = {
          ...node,
          attribs: {
            ...node.attribs,
            src: getFileUrl(id)
          }
        };
        return processNodeDefinitions.processDefaultNode(
          updatedNode,
          children,
          index
        );
      } else {
        return processNodeDefinitions.processDefaultNode(node, children, index);
      }
    }
  };
};
