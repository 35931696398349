import React from "react";
import { StoreContext } from "redux-react-hook";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "../redux/store";
// import { chakraTheme } from "../themes/chakraTheme";
import { IChildrenProp } from "../types/IChildrenProp";

interface IProps extends IChildrenProp {}

const Providers = ({ children }: IProps) => {
  const queryClient = new QueryClient();
  return (
    <StoreContext.Provider value={store}>
      <QueryClientProvider client={queryClient}>
        {/*@ts-ignore*/}
        <Provider store={store}>
            <BrowserRouter>
              {/*//zakomentowane z tego względu, że chakra nie wspiera IE11💩💩💩💩💩💩*/}
              {/*<ChakraProvider theme={chakraTheme}>*/}
              {children}
              {/*</ChakraProvider>*/}
            </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </StoreContext.Provider>
  );
};

export { Providers };
