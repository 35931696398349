import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const SkipLinks = ({ declarationOfAvailabilityUrl }) => (
  <nav aria-label="skip-links">
    <SkipLink href="#skip-link-aside">Przejdź do nawigacji</SkipLink>
    <SkipLink href="#skip-link-main">Przejdź do treści</SkipLink>
    <SkipLink href="#skip-link-search">Przejdź do wyszukiwarki</SkipLink>
    {declarationOfAvailabilityUrl && (
      <SkipLink href={declarationOfAvailabilityUrl}>
        Przejdź do deklaracji dostępności
      </SkipLink>
    )}
  </nav>
);

SkipLinks.propTypes = {
  declarationOfAvailabilityUrl: PropTypes.string
};

const SkipLink = styled.a`
  font-size: 0.875em;
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.defaultBackground};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  color: ${({ theme }) => theme.text.primaryColor};
  position: absolute;
  left: -999em;

  &:focus {
    left: 0;
    z-index: 999;
  }

  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
    text-decoration: none;
  }
`;

export default SkipLinks;
