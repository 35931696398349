import React, { FunctionComponent, ReactNode } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent,
  FallbackProps
} from "react-error-boundary";

import { Alert, Notification } from "../Alert";

interface IProps
  extends Omit<ErrorBoundaryPropsWithComponent, "FallbackComponent"> {
  children: ReactNode;
  fallback?: FunctionComponent<FallbackProps>;
}

const ErrorFallback = () => {
  return (
    <Alert type={Notification.ERROR}>
      Coś poszło nie tak, skontaktuj się z administratorem
    </Alert>
  );
};

const ErrorBoundary = ({
  fallback = ErrorFallback,
  children,
  ...restProps
}: IProps) => {
  return (
    <ReactErrorBoundary FallbackComponent={fallback} {...restProps}>
      {children}
    </ReactErrorBoundary>
  );
};

export { ErrorBoundary, ErrorFallback };
