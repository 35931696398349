/**
 * Created by fkurzawa on 28.06.18.
 */
import { amber, white, grey, blueGrey, red, lightBlue } from "material-colors";

const primaryTheme = {
  primaryColor: grey["500"],
  darkPrimaryColor: grey["700"],
  lightPrimaryColor: grey["200"],
  secondaryColor: red["500"],
  dividerColor: grey["400"],
  errorColor: red["600"],
  highlightBackground: amber["100"],

  asideBackground: lightBlue["900"],
  asideMenuItemColor: white,
  asideMenuItemBackground: "transparent",

  defaultBackground: white,

  footerLinkColor: white,
  footerGoUpColor: grey["400"],
  footerContentBackground: lightBlue["900"],

  toolbarBackground: lightBlue["900"],
  focusOutline: "#ff8c1a solid 2px",
  text: {
    primaryColor: blueGrey["800"],
    secondaryColor: blueGrey["600"],
    lightColor: blueGrey["200"]
  },

  buttons: {
    primary: {
      color: grey["50"],
      background: red["500"],
      hoverBackground: red["900"]
    },

    secondary: {
      color: grey["50"],
      background: blueGrey["500"],
      hoverBackground: blueGrey["900"]
    },

    default: {
      color: grey["800"],
      background: grey["300"],
      hoverBackground: grey["400"]
    },

    strongPrimary: {
      color: grey["50"],
      background: red["900"],
      hoverBackground: red["700"]
    },

    strongSecondary: {
      color: grey["50"],
      background: blueGrey["600"],
      hoverBackground: blueGrey["900"]
    }
  }
};

export default primaryTheme;
