import React from 'react';

import {toggleParagraphSpacing} from "utils/index";

import {DesktopAccessibilityButton} from "./DesktopAccessibilityButton";

const ParagraphSpacingButton = () => {
    return (
        <DesktopAccessibilityButton
            aria-label="Odstęp między akapitami"
            title="Odstęp między akapitami"
            onClick={toggleParagraphSpacing}
        >
            <span className="fa fa-arrows-v" />{" "}
            <span className="fa fa-paragraph" />
        </DesktopAccessibilityButton>
    );
};

export { ParagraphSpacingButton };