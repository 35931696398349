import { find } from "lodash";
import queryString from "query-string";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { useGetLocationParams } from "../../utils/useGetLocationParams";

import { IMenuArticleListSortField } from "modules/articles/types/IMenuArticleListSortField";

import { UnderlineSpan } from "components/UnderlineSpan";
import { SortType } from "../../../../types/enums/SortType";

interface IProps {
  fields: IMenuArticleListSortField[];
  sortDirName?: string;
}

const ArticlesSorting = ({ fields, sortDirName = "sort_dir" }: IProps) => {
  const parsedParams = useGetLocationParams();
  const currentSorting = find(fields, field => field.selected);

  if (fields.length > 0) {
    return (
      <Container>
        <Row>
          <Label>Sortuj według:</Label>
          <div>
            {fields.map((field, index) => (
              <SortFieldLink
                key={index}
                to={`?${queryString.stringify({
                  ...parsedParams,
                  sort: field.value
                })}`}
                isActive={() =>
                  field.value === (currentSorting && currentSorting.value)
                }
                isActualSorting={
                  field.value === (currentSorting && currentSorting.value)
                }
              >
                <UnderlineSpan>{field.name}</UnderlineSpan>
              </SortFieldLink>
            ))}
          </div>
        </Row>
        {currentSorting && currentSorting.value !== "weight" && (
          <Row>
            <Label>Kolejność:</Label>
            <div>
              <SortDirLink
                to={`?${queryString.stringify({
                  ...parsedParams,
                  sort: currentSorting.value,
                  [sortDirName]: SortType.ASC
                })}`}
                isActive={() => currentSorting.sortDir === SortType.ASC}
                isActualSorting={currentSorting.sortDir === SortType.ASC}
              >
                <span className="fa fa-chevron-up" />
                <UnderlineSpan>Rosnąco </UnderlineSpan>
              </SortDirLink>
              <SortDirLink
                to={`?${queryString.stringify({
                  ...parsedParams,
                  sort: currentSorting.value,
                  [sortDirName]: SortType.DESC
                })}`}
                isActive={() => currentSorting.sortDir === SortType.DESC}
                isActualSorting={currentSorting.sortDir === SortType.DESC}
              >
                <span className="fa fa-chevron-down" />
                <UnderlineSpan>Malejąco</UnderlineSpan>
              </SortDirLink>
            </div>
          </Row>
        )}
      </Container>
    );
  }

  return <></>;
};

const Container = styled.section`
  font-size: 0.75em;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

const Label = styled.div`
  color: ${({ theme }) => theme.text.primaryColor};
  flex-basis: 100px;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.text.secondaryColor};
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:active,
  &[aria-current="true"] {
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

const SortFieldLink = styled(StyledLink)`
  &:not(:last-child) {
    padding-right: 0.5rem;
    border-right: 1px solid ${({ theme }) => theme.dividerColor};
  }

  &:not(:first-child) {
    padding-left: 0.5rem;
  }

  color: ${props => props.isActualSorting && "#c62828"}};
  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

const SortDirLink = styled(StyledLink)`
  &:not(:last-child) {
    margin-right: 12px;
  }

  .fa {
    color: inherit;
    font-size: 0.625em;
    border: 1px solid;
    padding: 2px;
    margin-right: 6px;
  }

  color: ${props => props.isActualSorting && "#c62828"}};

  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

export { ArticlesSorting };
