/**
 * Created by fkurzawa on 26.03.18.
 */
import React, { useState } from "react";
import styled from "styled-components";

import { postArticleComment } from "api/articles";

import { ICommentForm } from "modules/articles/types/ICommentForm";
import { commentFormValidationSchema } from "modules/articles/schemas/commentFormValidationSchema";

import {
  CheckBoxField,
  Form,
  FormGroup,
  RecaptchaField,
  ResetButton,
  SpinnerButton,
  TextAreaField
} from "components/HookForm";
import { Alert, Notification } from "components/Alert";

interface IProps {
  articleId: number;
}

const CommentForm = ({ articleId }: IProps) => {
  const [alert, setAlert] = useState<{
    type: Notification;
    message: string;
  } | null>(null);

  const onSuccess = () => {
    setAlert({
      type: Notification.SUCCESS,
      message: "Komentarz został zapisany. Oczekuje na publikację."
    });
  };

  const onError = () => {
    setAlert({
      type: Notification.ERROR,
      message: "Zapisanie komentarza zakończone niepowodzeniem"
    });
  };

  const onSubmit = async (model: ICommentForm) => {
    setAlert(null);
    const validModel = {
      ...model,
      agreement: model.agreement ? 1 : 0
    };

    const formData = Object.keys(validModel).reduce(
      (obj: FormData, key: string) => {
        // @ts-ignore
        obj.append(key, validModel[key as keyof typeof validModel]);
        return obj;
      },
      new FormData()
    );

    await postArticleComment(articleId, formData)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  return (
    <>
      {alert && (
        <FormGroup>
          <Alert type={alert.type} onClose={() => setAlert(null)}>
            {alert.message}
          </Alert>
        </FormGroup>
      )}
      <Form<ICommentForm>
        onSubmit={onSubmit}
        defaultValues={{ comment: "", agreement: 0, "recaptcha-response": "" }}
        validationSchema={commentFormValidationSchema}
      >
        {() => {
          return (
            <>
              <FormGroup>
                <TextAreaField
                  rows={4}
                  label="Dodaj nowy komentarz:"
                  name="comment"
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="agreement"
                  label="Wyrażam zgodę na publikację komentarza"
                />
              </FormGroup>
              <FormGroup>
                <RecaptchaField name="recaptcha-response" />
                <FormButtons>
                  <SpinnerButton label="Wyślij" />
                  <ResetButton />
                </FormButtons>
              </FormGroup>
            </>
          );
        }}
      </Form>
    </>
  );
};

const FormButtons = styled.div`
  margin-top: 9px;
  display: flex;

  & > * {
    margin-right: 12px;
  }
`;

export { CommentForm };
