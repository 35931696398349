import React from "react";

import { setFontSize } from "utils/font-size";
import { FONT_SIZES } from "constants/font_sizes";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const BigLettersButton = () => {
  return (
    <DesktopAccessibilityButton
      title="Bardzo duża wielkość liter"
      onClick={() => setFontSize(FONT_SIZES.LARGE)}
    >
      A++
    </DesktopAccessibilityButton>
  );
};

export { BigLettersButton };
