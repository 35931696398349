import React from "react";
import { useMappedState } from "redux-react-hook";
import { useLocation } from "react-router-dom";

import { createSurveyBreadCrumbs } from "modules/survey/utils/createSurveyBreadCrumbs";
import { surveyKeys } from "modules/survey/keys";
import { getSurveyResults } from "modules/survey/api/getSurveyResults";
import { ISurvey } from "modules/survey/types/ISurvey";

import { useIsAuthorized } from "utils/useIsAuthorized";


import { RemoteDataSuspense } from "components/RemoteData/RemoteDataSuspense";
import { RedirectToSignIn } from "components/routes/index";
import { withBreadcrumbs } from "components/Breadcrumb/index";

import { SurveyHeader } from "../SurveyHeader";
import { SingleSurveyResult } from "./SingleSurveyResult";

interface IProps {
  surveyId: number;
  isDeprecated?: boolean;
  setContextBreadcrumbs: (item: any[]) => void;
}

const SurveyResults = ({
  surveyId,
  isDeprecated = false,
  setContextBreadcrumbs
}: IProps) => {
  const { friendlyUrl, urlType } = useMappedState(mapStateToProps);
  const { pathname } = useLocation();
  const isAuthorized = useIsAuthorized();

  return (
    <RemoteDataSuspense<ISurvey>
      fetcher={() => getSurveyResults(friendlyUrl, surveyId)}
      queryKey={[surveyKeys.surveyResult, surveyId]}
    >
      {({ loginRequired, completed, name, questions }) => {
        createSurveyBreadCrumbs(
          setContextBreadcrumbs,
          friendlyUrl,
          urlType,
          name
        );

        if (!isAuthorized(loginRequired)) {
          return <RedirectToSignIn redirectUrl={pathname} />;
        }

        return (
          <>
            <SurveyHeader
              completedCount={completed}
              isDeprecated={isDeprecated}
              surveyName={name}
            />
            <div>
              {questions?.map(answer => {
                return <SingleSurveyResult key={answer.id} answer={answer} />;
              })}
            </div>
          </>
        );
      }}
    </RemoteDataSuspense>
  );
};

const mapStateToProps = ({ authentication, context }: any) => ({
  friendlyUrl: context.contextFriendlyUrl,
  isAuthenticated: authentication.isAuthenticated,
  urlType: context.urlType
});

export default withBreadcrumbs(SurveyResults);
