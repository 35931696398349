import React, { useState } from "react";

import { getArticleVersions } from "api/articles";

import { articlesKeys } from "modules/articles/keys";

import { getOffsetFromPage, getPageFromOffset } from "../../../utils";

import Table from "./components/Table";
import Pagination from "../../pagination";

import { IArticleVersions } from "types/IArticleVersions";
import { Panel } from "../../Panel";
import { RemoteData } from "../../RemoteData/RemoteData";

interface IProps {
  articleId: number;
}

const Versions = ({ articleId }: IProps) => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const handleLimitChange = (limit: number) => {
    setLimit(limit);
    setOffset(0);
  };

  const handlePageChange = (page: number) => {
    setOffset(getOffsetFromPage(page, limit));
  };

  return (
    <RemoteData<IArticleVersions>
      queryKey={[articlesKeys.articleVersions, limit, offset, articleId]}
      fetcher={() => getArticleVersions(articleId, limit, offset)}
    >
      {({ elements, limit, offset, total }) => {
        if (total === 0) {
          return <></>;
        }
        setLimit(limit);
        setOffset(offset);
        const showPagination = total > 10;
        const totalPages = Math.ceil(total / limit);
        return (
          <Panel id="versions" title="Old versions of article" withCollapse>
            <Table items={elements} />
            {showPagination && (
              <Pagination
                showNumberOnPage
                showPages={totalPages > 1}
                currentNumber={limit}
                onNumberChange={handleLimitChange}
                current={getPageFromOffset(offset, limit)}
                total={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </Panel>
        );
      }}
    </RemoteData>
  );
};

export { Versions };
