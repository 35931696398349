import React from "react";
import { useFormContext } from "react-hook-form";

import Button from "../../buttons/Button";

const ResetButton = () => {
  const { reset } = useFormContext();

  return (
    <Button type="button" onClick={() => reset()} color="secondary">
      Wyczyść
    </Button>
  );
};

export { ResetButton };
