import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { getFileUrl } from "modules/articles/api/getFileUrl";

import { checkIsPhotoFormat } from "../utils/checkIsPhotoFormat";

import { MetricOverlay } from "./MetricOverlay";
import { PreviewModal } from "./PreviewModal";
import Button from "../../../../../buttons/Button";
import Translate from "../../../../../translate";

interface IProps {
  attachment: any;
}

const ItemActions = ({ attachment }: IProps) => {
  const [showMetric, setShowMetric] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const fileUrl = useMemo(() => getFileUrl(attachment.id), [attachment.id]);
  const download = () => {
    window.location.href = fileUrl;
  };
  const toggleMetric = () => setShowMetric(!showMetric);
  const togglePreview = () => setShowPreview(!showPreview);

  return (
    <Container>
      <OverlayButtonContainer>
        <Button
          type="button"
          title="Metryka pliku"
          aria-label="Metryka pliku"
          size="small"
          onClick={toggleMetric}
        >
          <span className="fa fa-info-circle" />
        </Button>
        <MetricOverlay
          attachment={attachment}
          show={showMetric}
          toggle={toggleMetric}
        />
      </OverlayButtonContainer>

      {attachment.showPreview && (
        <>
          <Button
            type="button"
            title="Podgląd pliku"
            aria-label="Podgląd pliku"
            size="small"
            onClick={togglePreview}
          >
            <span className="fa fa-eye" />
          </Button>
          <PreviewModal
            attachmentName={attachment.name}
            fileUrl={fileUrl}
            show={showPreview}
            toggle={togglePreview}
            isPhotoFormat={checkIsPhotoFormat(attachment.extension)}
          />
        </>
      )}

      {attachment.downloadable && (
        <Button
          type="button"
          onClick={download}
          color="strongPrimary"
          size="small"
          aria-label={`Pobierz plik ${attachment.name}.`}
        >
          <span className="fa fa-download" />
          &nbsp;<Translate>Download</Translate>
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  & > *:not(:last-child) {
    margin-right: 6px;
  }

  @media print {
    display: none;
  }
`;

const OverlayButtonContainer = styled.div`
  display: inline-block;
`;

export { ItemActions };
