import React from "react";
import styled from "styled-components";
import { Controller } from "react-hook-form";

import CheckboxLabel from "../form-fields/components/CheckboxLabel";
import { FieldPrototype } from "./FieldPrototype";
import { RedStar } from "./RedStar";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  isRequired?: boolean;
  label?: string;
}

const CheckBoxFieldPrototype = ({
  label,
  isRequired = true,
  name,
  handleChange,
  isChecked
}: IProps) => {
  return (
    <FieldPrototype isRequired={isRequired} name={name}>
      {({
        methods: {
          formState: { isSubmitting }
        }
      }) => (
        <Controller
          name={name}
          render={props => {
            return (
              <CheckboxLabel>
                <input
                  name={name}
                  type="checkbox"
                  onChange={handleChange}
                  onBlur={props.onBlur}
                  value={props.value}
                  checked={isChecked}
                  disabled={isSubmitting}
                />
                <div>
                  {isRequired ? <StyledRedStar /> : null} {label}
                </div>
              </CheckboxLabel>
            );
          }}
        />
      )}
    </FieldPrototype>
  );
};

const StyledRedStar = styled(RedStar)`
  margin-right: 5px;
`;

export { CheckBoxFieldPrototype };
