import React from "react";

import { getArticle } from "api/articles";

import { articlesKeys } from "modules/articles/keys";

import { RemoteData } from "components/RemoteData/RemoteData";
import { ArticleContent } from "components/ArticleContent";

import { IArticle } from "types/IArticle";

interface IProps {
  articleId: number;
  menuId: number;
}

const ArticleContentContainer = ({ articleId, menuId }: IProps) => {
  return (
    <RemoteData<IArticle>
      queryKey={[articlesKeys.mainArticle, articleId, menuId]}
      fetcher={() => getArticle(articleId)}
    >
      {article => {
        return <ArticleContent article={article} />;
      }}
    </RemoteData>
  );
};

export { ArticleContentContainer };
