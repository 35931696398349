import React from "react";
import styled from "styled-components";

import { Column, Grid, Row } from "../../../../TableComponents/MetricGrid";

import { IArticleAttachment } from "modules/articles/types/IArticleAttachment";

interface IProps {
  attachment: IArticleAttachment;
}

const AttachmentMetricPrintPreview = ({ attachment }: IProps) => {
  return (
    <AttachmentsMetricPreviewContainer>
      <Grid>
        <Row>
          <Column head>Podmiot udostępniający informację</Column>
          <Column>{attachment.unitName}</Column>
        </Row>
        <Row>
          <Column head>Osoba wprowadzająca informację</Column>
          <Column>{attachment.createUser.name}</Column>
        </Row>
        <Row>
          <Column head>Osoba odpowiedzialna za informację</Column>
          <Column>{attachment.responseUser.name}</Column>
        </Row>
        <Row>
          <Column head>Czas wytworzenia</Column>
          <Column>{attachment.createDate}</Column>
        </Row>
        <Row>
          <Column head>Czas publikacji</Column>
          <Column>{attachment.publishDate}</Column>
        </Row>
      </Grid>
    </AttachmentsMetricPreviewContainer>
  );
};

const AttachmentsMetricPreviewContainer = styled.div`
  display: none;
  @media print {
    display: initial;
    padding-left: 20px;
  }
`;

export { AttachmentMetricPrintPreview };
