import React, { ReactNode, useEffect, useRef, useState } from "react";

import styled from "styled-components";

interface IProps {
  button: ReactNode;
  children: ReactNode;
}

const Dropdown = ({ button, children }: IProps) => {
  const [containerVisible, setContainerVisible] = useState(false);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const mouseEventListener = (e: Event) => {
    if (
      // @ts-ignore
      containerRef.current.contains(e.target) ||
      // @ts-ignore
      buttonRef.current.contains(e.target)
    ) {
      return;
    }
    setContainerVisible(prevContainerVisible => {
      return !prevContainerVisible;
    });
  };

  useEffect(() => {
    if (containerVisible) {
      document.addEventListener("mousedown", mouseEventListener);
    }

    return () => {
      document.removeEventListener("mousedown", mouseEventListener);
    };
  }, [containerVisible]);

  const handleClick = () => {
    setContainerVisible(prevContainerVisible => {
      return !prevContainerVisible;
    });
  };

  return (
    <div>
      <div onClick={handleClick} ref={buttonRef}>
        {button}
      </div>
      <ChildrenContainer
        ref={containerRef}
        containerVisibility={containerVisible}
      >
        {children}
      </ChildrenContainer>
    </div>
  );
};

const ChildrenContainer = styled.div`
  z-index: 2;
  position: absolute;
  background: ${({ theme }) => theme.defaultBackground};
  min-width: 0;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.dividerColor};
  visibility: ${({ containerVisibility }: { containerVisibility: boolean }) =>
    containerVisibility ? "visible" : "hidden"};
  background: ${({ theme }) => theme.defaultBackground};
  border-color: ${({ theme }) => theme.dividerColor};
`;

export { Dropdown };
