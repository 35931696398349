import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";

import { FieldPrototype } from "./FieldPrototype";

import { IRadioGroupOption } from "./types";

interface IProps {
  label: string;
  name: string;
  options: IRadioGroupOption[];
  isRequired?: boolean;
}

const RadioGroupField = ({
  label,
  name,
  isRequired = true,
  options
}: IProps) => {
  const { setValue } = useFormContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.value, { shouldValidate: true });
  };

  return (
    <FieldPrototype isRequired={isRequired} label={label} name={name}>
      {({ methods: { getValues } }) => (
        <Controller
          name={name}
          render={() => (
            <>
              {options.map((option, i) => {
                const checked = getValues(name) === option.value;
                return (
                  <Label key={i}>
                    <input
                      type="radio"
                      name={name}
                      value={option.value}
                      checked={checked}
                      aria-checked={checked}
                      onChange={handleChange}
                    />
                    {option.label}
                  </Label>
                );
              })}
            </>
          )}
        />
      )}
    </FieldPrototype>
  );
};

const Label = styled.label`
  cursor: pointer;
  display: flex;
  margin-bottom: 0.25rem;
  align-items: center;
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.primaryColor};

  & > input[type="radio"] {
    margin-right: 6px;
  }
`;

export { RadioGroupField };
