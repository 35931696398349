import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, pure, withHandlers, withStateHandlers } from "recompose";

import { ForwardModal, ContactFormModal } from "./components";
import {
  getArticlePdfUrl,
  getArticleXmlUrl
} from "../../../../../api/articles";
import {
  getArticleVersionPdfUrl,
  getArticleVersionXmlUrl
} from "../../../../../api/articleVersions";
import Button from "../../../../buttons/Button";

const ActionButtons = ({
  articleId,
  contactFormId,
  contactFormOpened,
  closeContactForm,
  closeForward,
  downloadPdf,
  downloadXml,
  forwardOpened,
  isPreview,
  showContactForm,
  showForward,
  showDownloadPdf,
  showDownloadXml,
  showPrint,
  openContactForm,
  openForward,
  print
}) => (
  <div style={{ marginTop: "5px" }}>
    <Container>
      {showContactForm && (
        <Button
          aria-label={"Formularz kontaktowy"}
          disabled={isPreview}
          type={"button"}
          title={"Formularz kontaktowy"}
          size={"small"}
          onClick={openContactForm}
        >
          <span className={"fa fa-envelope-o"} />
        </Button>
      )}
      {showForward && (
        <Button
          aria-label={"Prześlij dalej"}
          disabled={isPreview}
          type={"button"}
          title={"Prześlij dalej"}
          size={"small"}
          onClick={openForward}
        >
          <span className={"fa fa-share"} />
        </Button>
      )}
      {showDownloadPdf && (
        <Button
          aria-label={"Wersja PDF"}
          disabled={isPreview}
          type={"button"}
          title={"Wersja PDF"}
          size={"small"}
          onClick={downloadPdf}
        >
          <span className={"fa fa-file-pdf-o"} />
        </Button>
      )}
      {showDownloadXml && (
        <Button
          aria-label={"Wersja XML"}
          disabled={isPreview}
          type={"button"}
          title={"Wersja XML"}
          size={"small"}
          onClick={downloadXml}
        >
          <span className={"fa fa-code"} />
        </Button>
      )}
      {showPrint && (
        <Button
          aria-label={"Drukuj"}
          disabled={isPreview}
          type={"button"}
          title={"Drukuj"}
          size={"small"}
          onClick={print}
        >
          <span className={"fa fa-print"} />
        </Button>
      )}
    </Container>
    {showForward && !isPreview && (
      <ForwardModal
        articleId={articleId}
        show={forwardOpened}
        onClose={closeForward}
      />
    )}
    {showContactForm && !isPreview && (
      <ContactFormModal
        contactFormId={contactFormId}
        show={contactFormOpened}
        onClose={closeContactForm}
      />
    )}
  </div>
);

ActionButtons.propTypes = {
  articleId: PropTypes.string.isRequired,
  closeContactForm: PropTypes.func.isRequired,
  closeForward: PropTypes.func.isRequired,
  contactFormId: PropTypes.number,
  contactFormOpened: PropTypes.bool,
  downloadPdf: PropTypes.func.isRequired,
  downloadXml: PropTypes.func.isRequired,
  forwardOpened: PropTypes.bool,
  isPreview: PropTypes.bool,
  openContactForm: PropTypes.func.isRequired,
  openForward: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  showContactForm: PropTypes.bool,
  showForward: PropTypes.bool,
  showDownloadPdf: PropTypes.bool,
  showDownloadXml: PropTypes.bool,
  showPrint: PropTypes.bool
};

const Container = styled.span`
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  @media print {
    display: none;
  }
`;

const enhance = compose(
  pure,
  withHandlers({
    downloadPdf: ({ articleId, isHistorical }) => () => {
      window.location.href = isHistorical
        ? getArticleVersionPdfUrl(articleId)
        : getArticlePdfUrl(articleId);
    },
    downloadXml: ({ articleId, isHistorical }) => () => {
      window.location.href = isHistorical
        ? getArticleVersionXmlUrl(articleId)
        : getArticleXmlUrl(articleId);
    },
    print: () => () => {
      window.print();
    }
  }),
  withStateHandlers(
    { forwardOpened: false },
    {
      openForward: () => () => ({ forwardOpened: true }),
      closeForward: () => () => ({ forwardOpened: false })
    }
  ),
  withStateHandlers(
    { contactFormOpened: false },
    {
      openContactForm: () => () => ({ contactFormOpened: true }),
      closeContactForm: () => () => ({ contactFormOpened: false })
    }
  )
);

export { ActionButtons, enhance };
export default enhance(ActionButtons);
