import React from "react";
import styled from "styled-components";
import { Column } from "../TableComponents/MetricGrid";
import { useMappedState } from "redux-react-hook";

const ContentColumn = ({ children }) => {
  const { isBifTheme, isContrast } = useMappedState(mapStateToProps);
  return (
    <StyledColumn isBifTheme={isBifTheme} isContrast={isContrast}>
      {children}
    </StyledColumn>
  );
};

const mapStateToProps = ({ context, accessibility }) => ({
  showCreateInformationDate:
    context.showCreateInformationDate.toString() === "true",
  isBifTheme: context.siteTheme === "bif",
  isContrast: accessibility.isContrast
});

const StyledColumn = styled(Column)`
  color: ${({ theme, isBifTheme, isContrast }) =>
    isBifTheme
      ? isContrast
        ? theme.text.primaryColor
        : "#ff8f00"
      : theme.text.primaryColor};
`;

export { ContentColumn };
