import React, { useEffect } from "react";
import styled from "styled-components";
import { useRemoveFocus } from "../../../utils/useRemoveFocus";

const NumberButton = ({ number, currentNumber, onNumberChange }) => {
  const [buttonRef, removeFocus] = useRemoveFocus();
  const isCurrentChoose = Number(number) === Number(currentNumber);

  useEffect(() => {
    if (!isCurrentChoose) {
      removeFocus();
    }
  }, [isCurrentChoose, removeFocus]);

  return (
    <StyledButton
      value={number}
      aria-current={isCurrentChoose}
      type={"button"}
      onClick={onNumberChange}
      ref={buttonRef}
    >
      {number}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  cursor: pointer;
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.primaryColor};
  background: ${({ theme }) => theme.defaultBackground};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  border-radius: 1px;
  padding: 0.375rem 0.625rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  &[aria-current="true"] {
    color: ${({ theme }) => theme.defaultBackground};
    background: ${({ theme }) => theme.secondaryColor};
  }
`;

export { NumberButton };
