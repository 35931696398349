import React from "react";

import { ColumnWithTranslate } from "./ColumnWithTranslate";
import { ContentColumn } from "../ContentColumn";
import { SpanWithTranslation } from "./SpanWithTranslation";
import { Row } from "../../TableComponents/MetricGrid";

interface IProps {
  expireDate: string;
}

const ExpirationDate = ({ expireDate }: IProps) => {
  return (
    <Row>
      <ColumnWithTranslate head>Date of archiving</ColumnWithTranslate>
      <ContentColumn>
        <SpanWithTranslation>{expireDate}</SpanWithTranslation>
      </ContentColumn>
    </Row>
  );
};

export { ExpirationDate };
