import React, { useState } from "react";
import useRouter from "use-react-router";
import queryString from "query-string";

import { articlesKeys } from "modules/articles/keys";
import { getMenuArticles } from "modules/articles/api/getMenuArticles";

import { useGetLocationParams } from "../../utils/useGetLocationParams";
import { useGetIsOutdated } from "../../utils/useGetIsOutdated";
import { useGetMenuId } from "../../utils/useGetMenuId";
import { filterFormParams } from "../../../../utils/search-params";

import { IMenuArticlesList } from "modules/articles/types/IMenuArticlesList";

import { Pagination } from "components/pagination";

import { ErrorPanel } from "components/ErrorPanel";

import ArticlesTable from "./ArticlesTable";
import { HideInPrintContent } from "../HideInPrintContent";
import { FilterBar } from "./components/FilterBar";
import { RemoteDataSuspense } from "components/RemoteData/RemoteDataSuspense";

interface IProps {
  allArticles: IMenuArticlesList;
}

const ArticlesTableContainer = ({ allArticles }: IProps) => {
  const { history } = useRouter();
  const [sortFields, setSortFields] = useState(allArticles.sortFields);
  const [searchFields, setSearchFields] = useState(allArticles.searchFields);
  const onPageChange = (page: number) => {
    const params = {
      ...parsedParams,
      page
    };
    history.push(`?${queryString.stringify(params)}`);
  };

  const onLimitChange = (limit: number) => {
    const firstPage = "1";
    const params = {
      ...parsedParams,
      limit: limit.toString(),
      page: firstPage
    };
    history.push(`?${queryString.stringify(params)}`);
  };

  const parsedParams = useGetLocationParams();
  const isOutdated = useGetIsOutdated();
  const menuId = useGetMenuId();

  const {
    limit = 10,
    sort,
    sort_dir,
    archived = isOutdated,
    tab,
    page = 1
  } = useGetLocationParams();

  const offset = (Number(page) - 1) * Number(limit);

  const searchParams = filterFormParams(parsedParams);

  if (allArticles.total === 1 && !allArticles.mainArticleId) {
    return <></>;
  }

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      <FilterBar
        listTitle={allArticles.listTitle}
        searchFields={searchFields}
        sortFields={sortFields}
      />
      <RemoteDataSuspense<IMenuArticlesList>
        queryKey={[
          articlesKeys.articleListSearch,
          parsedParams,
          menuId,
          searchParams,
          isOutdated
        ]}
        errorBoundaryProps={{
          resetKeys: [menuId]
        }}
        fetcher={() =>
          getMenuArticles(
            menuId,
            Number(limit),
            offset,
            sort,
            sort_dir,
            Number(archived),
            tab,
            searchParams
          )
        }
      >
        {menuArticles => {
          const total = menuArticles !== null ? menuArticles.total : 0;
          const totalPages = Math.ceil(total / Number(limit));
          setSortFields(menuArticles.sortFields);
          setSearchFields(menuArticles.searchFields);
          return (
            <>
              {menuArticles.articles.length > 0 ? (
                <div style={{ marginTop: "20px" }}>
                  <ArticlesTable
                    articles={menuArticles.articles}
                    columns={menuArticles.columns}
                    linkMoreEnabled={menuArticles.linkMoreEnabled}
                  />
                  <HideInPrintContent>
                    <Pagination
                      showNumberOnPage
                      currentNumber={Number(limit)}
                      onNumberChange={onLimitChange}
                      current={Number(page)}
                      total={totalPages}
                      onPageChange={onPageChange}
                    />
                  </HideInPrintContent>
                </div>
              ) : (
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <ErrorPanel header="Brak artykułów" />
                </div>
              )}
            </>
          );
        }}
      </RemoteDataSuspense>
    </div>
  );
};

export { ArticlesTableContainer };
