/**
 * Created by fkurzawa on 27.02.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TableCell from "./components/TableCell";

const ArticlesTableHeader = ({ columns }) => {
  return (
    <TableHead>
      <tr>
        {columns.map(column => (
          <Th key={column.id} width={column.width}>
            {column.name}
          </Th>
        ))}
      </tr>
    </TableHead>
  );
};

ArticlesTableHeader.propTypes = {
  columns: PropTypes.array
};

ArticlesTableHeader.defaultProps = {
  columns: []
};

const TableHead = styled.thead`
  border-top: 1px solid;
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.dividerColor};
  color: ${({ theme }) => theme.text.primaryColor};
  background: ${({ theme }) => theme.lightPrimaryColor};
  font-size: 0.875em;
`;

const Th = TableCell.withComponent("th");

export default ArticlesTableHeader;
