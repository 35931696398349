import React from "react";
import useMedia from "use-media";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { getRssUrl } from "api/contexts";

import { getFontSize, setFontSize } from "utils/font-size";
import { FONT_SIZES } from "constants/font_sizes";

import { ExternalLinkButton, LinkButton } from "../../../buttons";
import { LanguagesButton } from "./components/Buttons/LanguagesButton";
import { MobileAccessibilityMenu } from "./components/MobileAccessibilityMenu";
import { AccessibilityMenu } from "./components/AccessibilityMenu";
import Authentication from "../authentication/Authentication";
import { SearchBar } from "./components/search-bar/SearchBar";

const Tools = () => {
  const isMobile = useMedia({ maxWidth: "767px" });

  const {
    friendlyUrl,
    deafArticleUrl,
    helpArticleUrl,
    authenticationEnabled
  } = useMappedState(mapStateToProps);
  const rssUrl = getRssUrl(friendlyUrl);

  const increaseFontSize = () => {
    switch (getFontSize()) {
      case "0":
        setFontSize(FONT_SIZES.MEDIUM);
        break;
      case "1":
        setFontSize(FONT_SIZES.LARGE);
        break;
      default:
        return;
    }
  };

  const decreaseFontSize = () => {
    switch (getFontSize()) {
      case "1":
        setFontSize(FONT_SIZES.DEFAULT);
        break;
      case "2":
        setFontSize(FONT_SIZES.MEDIUM);
        break;
      default:
        return;
    }
  };

  return (
    <Container>
      {/*eslint-disable-next-line*/}
      <HiddenAccessKeyButton accessKey="L" onClick={increaseFontSize} />
      {/*eslint-disable-next-line*/}
      <HiddenAccessKeyButton accessKey="S" onClick={decreaseFontSize} />
      {/*eslint-disable-next-line*/}
      <LanguagesButton />
      <ExternalLinkButton
        href={rssUrl}
        id="rss"
        target="_blank"
        title="Rss"
        aria-label="Rss"
        color="secondary"
        type="button"
      >
        <span className="fa fa-rss" />
      </ExternalLinkButton>
      {helpArticleUrl && (
        <LinkButton
          to={`/${helpArticleUrl}`}
          title="Pomoc"
          aria-label="Pomoc"
          color="secondary"
          type="button"
          id="help-article"
        >
          <span className="fa fa-question-circle" />
        </LinkButton>
      )}
      {deafArticleUrl && (
        <LinkButton
          to={`/${deafArticleUrl}`}
          title="Informacja dla osób niesłyszących"
          aria-label="Informacja dla osób niesłyszących"
          color="secondary"
          type="button"
          id="deaf-article"
        >
          <span className="fa fa-deaf" />
        </LinkButton>
      )}
      {isMobile && (
        <StyledMobileAccessibilityMenu>
          <MobileAccessibilityMenu />
        </StyledMobileAccessibilityMenu>
      )}
      <AccessibilityAuthenticationContainer>
        {!isMobile && <AccessibilityMenu />}
        {authenticationEnabled && <Authentication />}
      </AccessibilityAuthenticationContainer>
      <SearchBar />
    </Container>
  );
};

const mapStateToProps = ({ context }: any) => ({
  friendlyUrl: context.contextFriendlyUrl,
  helpArticleUrl: context.helpArticleUrl,
  deafArticleUrl: context.deafArticleUrl,
  authenticationEnabled: context.userLogInEnabled
});

const StyledMobileAccessibilityMenu = styled.div`
  display: flex;
  align-self: flex-start;
`;

const AccessibilityAuthenticationContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const Container = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const HiddenAccessKeyButton = styled.span`
  display: none;
`;

export { Tools };
