/**
 * Created by fkurzawa on 26.02.18.
 */
import React from "react";
import PropTypes from "prop-types";
import { compose, withHandlers } from "recompose";
import styled from "styled-components";
import { NumberButton } from "./NumberButton";

const NumberOnPage = ({ numbersSet, currentNumber, onNumberChange }) => {
  return (
    <Container>
      {numbersSet.map(number => {
        return (
          <NumberButton
            key={number}
            number={number}
            currentNumber={currentNumber}
            onNumberChange={onNumberChange}
          />
        );
      })}
      <Text>wyników</Text>
    </Container>
  );
};

NumberOnPage.propTypes = {
  numbersSet: PropTypes.array.isRequired,
  currentNumber: PropTypes.number,
  onNumberChange: PropTypes.func
};

NumberOnPage.defaultProps = {
  numbersSet: [10, 20, 30, 40, 50],
  onNumberChange: () => {}
};

const Container = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Text = styled.span`
  color: ${({ theme }) => theme.text.primaryColor};
  text-transform: uppercase;
  font-size: 0.875em;
  margin-left: 0.5rem;
`;

const composed = compose(
  withHandlers({
    onNumberChange: ({ onNumberChange }) => event => {
      onNumberChange(Number(event.target.value));
    }
  })
);

export default composed(NumberOnPage);
