import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LightBox from "react-images";
import { compose, withProps, mapProps, withStateHandlers } from "recompose";
import { omit } from "lodash";
import { Thumbnail } from "./components/Thumbnail";
import { getFileThumbnailUrl } from "../../../api/files";

import { getFileUrl } from "modules/articles/api/getFileUrl";

const Gallery = ({
  images,
  lightBoxOpened,
  openLightBox,
  closeLightBox,
  currentImage,
  goToPreviousImage,
  goToNextImage,
  goToImage
}) => {
  return (
    <React.Fragment>
      <ThumbnailsContainer>
        {images.map((image, index) => (
          <Thumbnail
            key={index}
            image={image}
            onClick={() => openLightBox(index)}
          />
        ))}
      </ThumbnailsContainer>
      <LightBox
        images={images}
        isOpen={lightBoxOpened}
        imageCountSeparator=" z "
        onClose={closeLightBox}
        currentImage={currentImage}
        onClickPrev={goToPreviousImage}
        onClickNext={goToNextImage}
        closeButtonTitle="Zamknij (Esc)"
        leftArrowTitle="Poprzedni obraz (Lewa strzałka)"
        rightArrowTitle="Kolejny obraz (Prawa strzałka)"
        showThumbnails
        onClickThumbnail={goToImage}
      />
    </React.Fragment>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
  lightBoxOpened: PropTypes.bool,
  openLightBox: PropTypes.func,
  closeLightBox: PropTypes.func,
  currentImage: PropTypes.number,
  goToPreviousImage: PropTypes.func,
  goToNextImage: PropTypes.func,
  goToImage: PropTypes.func
};

Gallery.defaultProps = {
  images: [],
  lightBoxOpened: false,
  openLightBox: () => {},
  closeLightBox: () => {},
  currentImage: 0,
  goToPreviousImage: () => {},
  goToNextImage: () => {},
  goToImage: () => {}
};

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    margin: 6px;
  }
`;

const composed = compose(
  withProps(({ files }) => ({
    images: files.map(file => ({
      id: file.id,
      src: getFileUrl(file.id),
      thumbnailSrc: getFileThumbnailUrl(file.id),
      caption: file.name,
      alt: file.name
    }))
  })),
  mapProps(props => omit(props, ["files"])),
  withStateHandlers(
    () => ({
      lightBoxOpened: false,
      currentImage: 0
    }),
    {
      openLightBox: ({ lightBoxOpened }) => imageIndex => ({
        lightBoxOpened: true,
        currentImage: imageIndex
      }),
      closeLightBox: ({ lightBoxOpened }) => () => ({ lightBoxOpened: false }),
      goToNextImage: ({ currentImage }) => () => ({
        currentImage: ++currentImage
      }),
      goToPreviousImage: ({ currentImage }) => () => ({
        currentImage: --currentImage
      }),
      goToImage: () => currentImage => ({ currentImage })
    }
  )
);

export default composed(Gallery);
