import React from "react";

import { Column, Row } from "../../TableComponents/MetricGrid";
import { ColumnWithTranslate } from "./ColumnWithTranslate";
import { User } from "../../TableComponents/User";

import { IUser } from "types/IUser";

interface IProps {
  user: IUser;
}

const ResponsibleUser = ({ user }: IProps) => {
  return (
    <Row>
      <ColumnWithTranslate head>Responsible person</ColumnWithTranslate>
      <Column>
        <User user={user} />
      </Column>
    </Row>
  );
};

export { ResponsibleUser };
