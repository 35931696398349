/**
 * Created by fkurzawa on 27.03.18.
 */
import styled from "styled-components";
import Label from "../../HookForm/Label";

const CheckboxLabel = styled(Label)`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > input[type="checkbox"] {
    margin-right: 6px;
  }
`;

export default CheckboxLabel;
