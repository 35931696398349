import React from "react";

import { ColumnWithTranslate } from "./ColumnWithTranslate";
import { ContentColumn } from "../ContentColumn";
import { SpanWithTranslation } from "./SpanWithTranslation";
import { Row } from "../../TableComponents/MetricGrid";

interface IProps {
  publicationDate: string;
}

const TimeOfPublication = ({ publicationDate }: IProps) => {
  return (
    <Row>
      <ColumnWithTranslate head>Time of publication</ColumnWithTranslate>
      <ContentColumn>
        <SpanWithTranslation>{publicationDate}</SpanWithTranslation>
      </ContentColumn>
    </Row>
  );
};

export { TimeOfPublication };
