import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

const VisitorsCounter = () => {
  const { counter } = useMappedState(mapStateToProps);
  return (
    <Container id="statistics_main">Liczba odwiedzin: {counter || 0}</Container>
  );
};

const Container = styled.section`
  color: ${({ theme }) => theme.asideMenuItemColor || theme.text.primaryColor};
  font-size: 0.75em;
  padding: 1rem;
`;

const mapStateToProps = ({ context }) => ({
  counter: context.visitorsCounter
});

export { VisitorsCounter };
