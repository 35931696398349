import React, { useState } from "react";
import styled from "styled-components";

import { FormGroup } from "components/HookForm";

import { ForwardModalForm } from "./ForwardModalForm";
import Modal from "./../../../../../modal/Modal";
import { Alert, Notification } from "../../../../../Alert";

interface IProps {
  show: boolean;
  articleId: number;
  onClose: () => void;
}

const ForwardModal = ({ show, articleId, onClose }: IProps) => {
  const [alert, setAlert] = useState<{
    type: Notification;
    message: string;
  } | null>(null);

  const onSuccess = () => {
    setAlert({
      type: Notification.SUCCESS,
      message: "Wiadomość została wysłana"
    });
  };

  const onError = (errorMessage: string) => {
    setAlert({
      type: Notification.ERROR,
      message: errorMessage
    });
  };

  const handleClose = () => {
    onClose();
    setAlert(null);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Content>
        <FormGroup>
          {alert && <Alert type={alert.type}>{alert.message}</Alert>}
        </FormGroup>
        <ForwardModalForm
          onClose={handleClose}
          onError={onError}
          onSuccess={onSuccess}
          articleId={articleId}
        />
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: 1rem;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
`;

export { ForwardModal };
