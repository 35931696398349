import React from "react";
import { useFormContext } from "react-hook-form";

import { FIELD_TYPES } from "modules/survey/types/FieldTypes";
import {
  ISurveyQuestion,
  ISurveyQuestionField
} from "modules/survey/types/ISurveyQuestion";

import { RadioGroupField, TextField } from "components/HookForm";

interface IProps {
  fieldName: string;
  questionField: ISurveyQuestion;
}

const SingleChoiceField = ({ fieldName, questionField }: IProps) => {
  const {
    watch,
  } = useFormContext();

  const name = createRadioFieldName(fieldName, questionField.type);
  const isOpen = questionField.isOpen === 1;
  const withEmpty = !questionField.isRequired;
  const options = createOptions(questionField.fields || [], withEmpty, isOpen);

  const showOpenField = watch(name) === "ownAnswer";
  const showCommentField = false;
  const ownAnswerFieldName = `${name}_ownAnswer`;
  const commentFieldName = `${name}_comment`;

  return (
    <>
      <RadioGroupField
        name={name}
        label={questionField.name}
        options={options}
        isRequired={questionField.isRequired === 1}
      />

      {showOpenField && (
        <TextField
          name={ownAnswerFieldName}
          label="Własna odpowiedź"
          isRequired={questionField.isRequired === 1}
        />
      )}

      {showCommentField && (
        <TextField name={commentFieldName} label="Dodaj komentarz" />
      )}
    </>
  );
};

const createOptions = (
  fields: ISurveyQuestionField[],
  withEmpty: boolean,
  withOpen: boolean
) => {
  let options = fields.map(field => ({
    label: field.name,
    value: field.id.toString()
  }));
  if (withEmpty) {
    options = [
      {
        label: "Pozostaw puste",
        value: ""
      },
      ...options
    ];
  }
  if (withOpen) {
    options = [
      ...options,
      {
        label: "Podaj własną odpowiedź",
        value: "ownAnswer"
      }
    ];
  }
  return options;
};

const createRadioFieldName = (name: string, type: FIELD_TYPES) => {
  if (type === FIELD_TYPES.LIST_ONE_OPEN) {
    return `${name}_listOneOpen`;
  } else if (type === FIELD_TYPES.LIST_ONE_COMMENT) {
    return `${name}_listOneComment`;
  } else {
    return name;
  }
};

export { SingleChoiceField };
