import React from "react";
import useRouter from "use-react-router";
import { useDispatch, useMappedState } from "redux-react-hook";

import { getArticle } from "../../api/articles";
import { getArticleVersion } from "../../api/articleVersions";
import { createBreadcrumbItem } from "../../utils/breadcrumbs";

import { setDocumentTitle } from "redux/modules/document-title";
import { fetchMenuSucceeded, selectMenuItem } from "redux/modules/menu";
import { articlesKeys } from "modules/articles/keys";

import { getMenu } from "../../api/menus";

import { ArticleContent } from "components/ArticleContent";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import withBreadcrumbs from "components/Breadcrumb/withBreadcrumbs";
import { RemoteData } from "components/RemoteData/RemoteData";
import { ErrorPanel } from "components/ErrorPanel";

import { IArticle } from "../../types/IArticle";
import { IMenuElement } from "../../types/IMenuElement";

interface IProps {
  setContextBreadcrumbs: (item: any[]) => void;
}

const Article = ({ setContextBreadcrumbs }: IProps) => {
  const { location, match } = useRouter<{ method: string; id: string }>();
  const { startArticleId } = useMappedState(mapStateToProps);
  const dispatch = useDispatch();
  const id = location.pathname === "/" ? startArticleId : match.params.id;

  const fetchArticle = () => {
    const isVersion =
      match.params.method === "v" || match.params.method === "getVersion";

    return isVersion ? getArticleVersion(id) : getArticle(id);
  };

  return (
    <div data-testid="test-ArticleLegacy">
      <Breadcrumb />
      <RemoteData<IArticle>
        queryKey={[articlesKeys.articleVersion, id]}
        fetcher={fetchArticle}
        errorFallback={() => {
          setContextBreadcrumbs([]);
          return <ErrorPanel header="404" content="Nie odnaleziono artykułu" />;
        }}
      >
        {article => {
          const breadcrumbItems = [
            ...article.mainMenuPath
              .filter(menuElement => !!menuElement.parentId)
              .map(menuElement =>
                createBreadcrumbItem(menuElement.name, menuElement.url)
              )
              .reverse(),
            createBreadcrumbItem(article.title, location.pathname)
          ];
          setContextBreadcrumbs(breadcrumbItems);

          dispatch(selectMenuItem(article.mainMenuId));

          selectMenuItem(article.mainMenuId);

          const currentPathElement = article.mainMenuPath[0];
          dispatch(setDocumentTitle(currentPathElement.name));

          return (
            <RemoteData<IMenuElement>
              queryKey={[articlesKeys.menuItems, article.mainMenuId]}
              fetcher={() => getMenu(article.mainMenuId)}
            >
              {menuItems => {
                dispatch(fetchMenuSucceeded(menuItems));
                return <ArticleContent article={article} />;
              }}
            </RemoteData>
          );
        }}
      </RemoteData>
    </div>
  );
};

const mapStateToProps = ({ context }: any) => ({
  startArticleId: context.startArticleId,
  context: context
});

export default withBreadcrumbs(Article);
