import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import NumberOnPage from "./components/NumberOnPage";
import { Pages } from "./Pages";

const Pagination = ({
  current,
  total,
  showNumberOnPage,
  currentNumber,
  onNumberChange,
  onPageChange,
  numbersSet = [10, 20, 30, 40, 50],
  showPages = true
}) => {
  return (
    <PaginationContainer>
      {showNumberOnPage && (
        <NumberOnPage
          numbersSet={numbersSet}
          currentNumber={currentNumber}
          onNumberChange={onNumberChange}
        />
      )}
      {showPages && (
        <Pages
          current={current}
          onPageChange={onPageChange}
          totalPages={total}
        />
      )}
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  showNumberOnPage: PropTypes.bool,
  showPages: PropTypes.bool,
  numbersSet: PropTypes.array,
  currentNumber: PropTypes.number,
  onNumberChange: PropTypes.func,
  current: PropTypes.number,
  total: PropTypes.number,
  pagesLimit: PropTypes.number,
  onPageChange: PropTypes.func,
  onResize: PropTypes.func
};

Pagination.defaultProps = {
  showPages: true,
  current: 1,
  total: 1,
  pagesLimit: 1,
  currentNumber: 10,
  numbersSet: [10, 20, 30, 40, 50],
  onResize: () => {}
};

const PaginationContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    & > *:not(:last-child) {
      margin-right: 0.75rem;
    }
  }

  @media print {
    display: none;
  }
`;

export default Pagination;
