import React from "react";
import { useHistory } from "react-router-dom";
import { useMappedState } from "redux-react-hook";
import { QueryClient } from "react-query";
import styled from "styled-components";

import { Dropdown } from "../../../../../Dropdown";
import { DropdownButton } from "./DropdownButton";
import { applicationKeys } from "../../../../../../App/keys";

const LanguagesButton = () => {
  const queryClient = new QueryClient();
  const history = useHistory();

  const { availableLanguages, currentLangCode } = useMappedState(
    mapStateToProps
  );

  const onChangeLanguage = async (startLink: string) => {
    history.push(`/${startLink}`);
    await queryClient.removeQueries(applicationKeys.application);
  };

  return (
    <Dropdown
      button={
        <DropdownButton id="languagesButton-dropdown" title="Wersja językowa">
          <span className="fa fa-flag" /> <span className="fa fa-caret-down" />
        </DropdownButton>
      }
    >
      {availableLanguages.map(
        (
          lang: { langCode: string; langName: string; startLink: string },
          index: number
        ) => {
          const [langCode, langTitle] = lang.langCode.split("_");
          const isCurrent = lang.langCode === currentLangCode;

          return (
            <StyledButton isCurrent={isCurrent} key={index}>
              <button
                disabled={isCurrent}
                className={`dropdown-item${isCurrent ? " disabled" : ""}`}
                title={lang.langName}
                onClick={() => onChangeLanguage(lang.startLink)}
              >
                <span
                  className={`flag-icon flag-icon-${langTitle.toLowerCase()}`}
                />{" "}
                {langCode.toUpperCase()}
              </button>
            </StyledButton>
          );
        }
      )}
    </Dropdown>
  );
};

const StyledButton = styled.div`
  background: ${({ theme, isCurrent }) => isCurrent && theme.lightPrimaryColor};
  pointer-events: ${({ isCurrent }) => (isCurrent ? "none" : "auto")};

  &:hover {
    background: ${({ theme }) => theme.contentBackground};
  }
`;

const mapStateToProps = ({ context, language }: any) => ({
  availableLanguages: context.availableLanguages,
  currentLangCode: language.langCode
});

export { LanguagesButton };
