/**
 * Created by fkurzawa on 29.01.18.
 */
import axios from "axios";
import ApiConfig from "./api.config";
import { IArticle } from "../types/IArticle";
import { ILatestArticle } from "../types/ILatestArticle";
import { IArticleRegistries } from "../modules/contentRegistry/types/IArticleRegistries";
import { IArticleVersions } from "../types/IArticleVersions";

const apiUrl = `${ApiConfig.getEntryPoint()}`;

export const getArticle = (articleId: number): Promise<IArticle> => {
  return axios
    .get(`${apiUrl}articles/${articleId}`)
    .then(response => response.data);
};

export const getLatestArticles = (
  friendlyUrl: string = "default"
): Promise<ILatestArticle[]> => {
  return axios
    .get(`${ApiConfig.getEntryPoint()}contexts/${friendlyUrl}/latest-articles`)
    .then(response => response.data);
};

export const getArticleRegistries = (
  articleId: number,
  limit: number = 10,
  offset: number = 0
): Promise<IArticleRegistries> => {
  return axios
    .get(`${apiUrl}articles/${articleId}/registries`, {
      params: {
        limit,
        offset
      }
    })
    .then(response => response.data);
};

export const getArticleVersions = (
  articleId: number,
  limit: number = 10,
  offset: number = 0
): Promise<IArticleVersions> => {
  return axios
    .get(`${apiUrl}articles/${articleId}/versions`, {
      params: {
        limit,
        offset
      }
    })
    .then(response => response.data);
};

export const postArticleComment = (articleId: number, data: any) =>
  axios.post(`${apiUrl}articles/${articleId}/comments`, data);

export const getArticleXmlUrl = (articleId: number) =>
  `${apiUrl}articles/${articleId}/xml`;

export const getArticlePdfUrl = (articleId: number) =>
  `${apiUrl}articles/${articleId}/pdf`;

export const postArticleForward = (articleId: number, data: any) =>
  axios.post(`${apiUrl}articles/${articleId}/forward`, data);
