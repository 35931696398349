import React from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useLocation } from "react-router-dom";

import { useIsAuthorized } from "utils/useIsAuthorized";
import { BIP_MODULES } from "../../../../constants/bip_modules";

import { createSurveyBreadCrumbs } from "modules/survey/utils/createSurveyBreadCrumbs";
import { addCompletedSurveyIdToLocalStorage } from "modules/survey/utils/addCompletedSurveyIdToLocalStorage";
import { getSurvey } from "modules/survey/api/getSurvey";
import { postSurvey } from "modules/survey/api/postSurvey";
import { ISurvey } from "modules/survey/types/ISurvey";
import { surveyKeys } from "modules/survey/keys";
import { selectMenuItem } from "redux/modules/menu";
import { createDefaultSurveyFormValues } from "modules/survey/utils/createDefaultSurveyFormValues";
import { ISurveyQuestionField } from "modules/survey/types/ISurveyQuestion";

import { FormGroup } from "components/HookForm/FormGroup";
import {
  Form,
  formFieldsGeneratorSchemaAdapter,
  SpinnerButton
} from "components/HookForm";
import { RedirectToSignIn } from "components/routes/index";
import { RemoteData } from "components/RemoteData/RemoteData";
import { withBreadcrumbs } from "components/Breadcrumb/index";

import { ErrorFallback } from "components/ErrorBoundary";
import { Alert, Notification } from "components/Alert";

import { SurveyHeader } from "../SurveyHeader";
import { SurveyQuestionField } from "./components/SurveyQuestionField";
import { surveyFormValidationSchema } from "./surveyFormValidationSchema";
import {
  INotificationType,
  toastNotification
} from "components/ToastNotification/toastNotification";

interface IProps {
  surveyId: number;
  setIsCompleted: (value: boolean) => void;
  setContextBreadcrumbs: (item: any[]) => void;
}

const SurveyForm = ({
  surveyId,
  setIsCompleted,
  setContextBreadcrumbs
}: IProps) => {
  const { friendlyUrl, urlType } = useMappedState(mapStateToProps);
  const { pathname } = useLocation();
  const isAuthorized = useIsAuthorized();
  const dispatch = useDispatch();
  // const toastNotification = useToastNotification();

  const purifyKey = (key: string) => {
    return key.replace(/_(.+?)(?=_|$)/g, (m, s) => `[${s}]`);
  };

  const onSubmit = async (model: any) => {
    const formData = new FormData();
    Object.keys(model).forEach(formKey => {
      //jeśli array to znaczy, że jest to pole rankingowe
      if (Array.isArray(model[formKey])) {
        model[formKey].forEach((item: ISurveyQuestionField, index: number) => {
          const itemFormKey = `${formKey}_${item.id}`;
          formData.append(purifyKey(itemFormKey), (index + 1).toString());
        });
        return;
      }
      //jeśli obiekt tzn że jest to lista oneMany, open itd
      if (typeof model[formKey] === "object") {
        Object.keys(model[formKey]).forEach((objectKey: string) => {
          Object.keys(model[formKey][objectKey] || {}).forEach(answerKey => {
            const itemFormKey = `${formKey}_${objectKey}${answerKey}`;
            if (model[formKey][objectKey][answerKey]) {
              formData.append(
                purifyKey(itemFormKey),
                model[formKey][objectKey][answerKey].toString()
              );
            }
          });
        });
        return;
      }
      if (model[formKey]) {
        formData.append(purifyKey(formKey), model[formKey]);
      }
    });
    dispatch(selectMenuItem(BIP_MODULES.SURVEY.id));
    try {
      await postSurvey(friendlyUrl, surveyId, formData);
      setIsCompleted(true);
      addCompletedSurveyIdToLocalStorage(surveyId);
      toastNotification(
        INotificationType.SUCCESS,
        "Ankieta",
        "Potwierdzamy otrzymanie dokumentu/ankiety."
      );
    } catch (e) {
      toastNotification(
        INotificationType.ERROR,
        "Ankieta",
        "Niepoprawnie wypełniono ankietę."
      );
    }
  };

  return (
    <RemoteData<ISurvey>
      queryKey={[surveyKeys.surveyForm, surveyId]}
      fetcher={() => getSurvey(friendlyUrl, surveyId)}
      errorFallback={e => {
        // @ts-ignore
        if (e.error?.response?.status === 400) {
          return <Alert type={Notification.ERROR}>Ankieta nieaktualna</Alert>;
        }
        return <ErrorFallback />;
      }}
    >
      {({ questions, completed, isDeprecated, name, loginRequired }) => {
        if (!isAuthorized(loginRequired)) {
          return <RedirectToSignIn redirectUrl={pathname} />;
        }

        if (isDeprecated) {
          return (
            <>
              <SurveyHeader
                completedCount={completed}
                isDeprecated={isDeprecated}
                surveyName={name}
              />
            </>
          );
        }

        createSurveyBreadCrumbs(
          setContextBreadcrumbs,
          friendlyUrl,
          urlType,
          name
        );
        return (
          <>
            <SurveyHeader
              completedCount={completed}
              isDeprecated={false}
              surveyName={name}
            />
            <Form
              defaultValues={createDefaultSurveyFormValues(questions)}
              onSubmit={onSubmit}
              resetOnSubmit={false}
              validationSchema={formFieldsGeneratorSchemaAdapter(
                questions,
                surveyFormValidationSchema
              )}
            >
              {() => {
                return (
                  <>
                    {questions?.map(question => (
                      <FormGroup key={question.id}>
                        <SurveyQuestionField questionField={question} />
                      </FormGroup>
                    ))}
                    <SpinnerButton label="Wyślij" color={"primary"} />
                  </>
                );
              }}
            </Form>
          </>
        );
      }}
    </RemoteData>
  );
};

const mapStateToProps = ({ authentication, context }: any) => ({
  isAuthenticated: authentication.isAuthenticated,
  friendlyUrl: context.contextFriendlyUrl,
  urlType: context.urlType
});

export default withBreadcrumbs(SurveyForm);
