import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { Menu } from "./components/Menu";
import { VisitorsCounter } from "./components/VisitorsCounter";

const Aside = () => {
  const { isBifTheme, visitorsCounterEnabled } = useMappedState(
    mapStateToProps
  );
  return (
    <Container id="skip-link-aside" isBifTheme={isBifTheme}>
      <Menu />
      {visitorsCounterEnabled && <VisitorsCounter />}
    </Container>
  );
};

Aside.propTypes = {
  visitorsCounterEnabled: PropTypes.bool,
  isBifTheme: PropTypes.bool
};

const Container = styled.div`
  margin-top: -1px;
  background: ${({ theme }) => theme.asideBackground};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 1024px) {
    flex: 0 0 250px;
    min-width: 250px;

    position: sticky;
    top: 68px;
    max-height: calc(100vh - 68px);
    overflow-y: auto;
    //W przypadku gdy suwak nie będzie porządany w menu wtedy zastosować poniższy kod
    // max-height: ${({ isBifTheme }) =>
      isBifTheme ? "initial" : "calc(100vh - 68px)"};
    //  overflow-y: ${({ isBifTheme }) => (isBifTheme ? "inherit" : "auto")};
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-height: none;
  }

  @media print {
    display: none;
  }
`;

const mapStateToProps = ({ context }) => ({
  visitorsCounterEnabled: context.visitorsCounterEnabled,
  isBifTheme: context.siteTheme === "bif"
});

export { Aside };
