/**
 * Created by fkurzawa on 20.03.18.
 */
import React from "react";
import PropTypes from "prop-types";

import { User } from "../../../../TableComponents/User";
import { Grid, Row, Column } from "../../../../TableComponents/MetricGrid";

const AttachmentMetric = ({ attachment }) => {
  return (
    <Grid>
      <Row>
        <Column head>Podmiot udostępniający informację</Column>
        <Column>{attachment.unitName}</Column>
      </Row>
      <Row>
        <Column head>Osoba wprowadzająca informację</Column>
        <Column>
          <User user={attachment.createUser} />
        </Column>
      </Row>
      <Row>
        <Column head>Osoba odpowiedzialna za informację</Column>
        <Column>
          <User user={attachment.responseUser} />
        </Column>
      </Row>
      <Row>
        <Column head>Czas wytworzenia</Column>
        <Column>{attachment.createDate}</Column>
      </Row>
      <Row>
        <Column head>Czas publikacji</Column>
        <Column>{attachment.publishDate}</Column>
      </Row>
    </Grid>
  );
};

AttachmentMetric.propTypes = {
  attachment: PropTypes.object.isRequired
};

export { AttachmentMetric };
