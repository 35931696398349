import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { QueryClient } from "react-query";
import { useDispatch } from "redux-react-hook";

import { getOrganizationalUnits } from "api/contexts";
import { getAllByUrl } from "api/urls";

import { prepareUrl } from "redux/utils/prepareUrl";
import { fetchContextSucceeded } from "redux/modules/context";
import {
  fetchAdditionalMenuSucceeded,
  fetchMenuSucceeded
} from "redux/modules/menu";
import { createSearchUrl } from "redux/utils/createSearchUrl";
import { shouldCreateAdditionalMenuItem } from "redux/utils/shouldCreateAdditionalMenuItem";
import { getEnabledMenuElements } from "utils/menu";

import { createRedirectUrl } from "../../../../routes/utils/createRedirectUrl";

import { RemoteData } from "../../../../RemoteData/RemoteData";

import { applicationKeys } from "App/keys";
import { IUnitTypeWithUnits } from "types/IUnitTypeWithUnits";

const OrganizationalUnits = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = new QueryClient();

  const onContextChange = async (friendlyUrl: string) => {
    const newGetAll = await getAllByUrl(prepareUrl(`/${friendlyUrl}`));
    const { context, resource, menu } = newGetAll;

    const contextFriendlyUrl = resource.context || "default";
    const separator = context.urlType === "full" ? "/" : ",";
    const isNotDefaultContext = contextFriendlyUrl !== "default";
    const unit = `${contextFriendlyUrl === "default" ? "" : friendlyUrl}`;
    const hasSeparator = isNotDefaultContext && context.startUrl !== "";

    const searchUrl = createSearchUrl(
      context.urlType,
      resource.lang,
      contextFriendlyUrl
    );

    const enhancedContext = {
      ...context,
      contextFriendlyUrl,
      searchUrl
    };

    const redirectUrl = createRedirectUrl(
      unit,
      hasSeparator,
      separator,
      newGetAll.context.startArticleId,
      newGetAll.context.startMenuId,
      newGetAll.context.startUrl
    );

    history.push(redirectUrl);

    dispatch(fetchContextSucceeded(enhancedContext));
    dispatch(fetchMenuSucceeded(menu || []));
    if (shouldCreateAdditionalMenuItem(context)) {
      const additionalMenuElements = getEnabledMenuElements(
        resource.lang,
        enhancedContext
      );
      dispatch(fetchAdditionalMenuSucceeded(additionalMenuElements));
    }

    await queryClient.setQueryData(applicationKeys.application, newGetAll);
  };

  return (
    <RemoteData<IUnitTypeWithUnits[]>
      queryKey={["organizational-units"]}
      fetcher={() => getOrganizationalUnits("default")}
    >
      {data => {
        return (
          <UnitsList>
            {data.map(unit => (
              <ListItem key={unit.id}>
                <UnitName>{unit.name}</UnitName>
                <UnitsList>
                  {unit.units.map(
                    ({
                      id,
                      isExternal,
                      externalLink,
                      friendlyUrl,
                      fullName
                    }) => (
                      <ListItem key={id}>
                        {isExternal ? (
                          <ExternalLink target="_blank" href={externalLink}>
                            {fullName}
                          </ExternalLink>
                        ) : (
                          <Link onClick={() => onContextChange(friendlyUrl)}>
                            {fullName}
                          </Link>
                        )}
                      </ListItem>
                    )
                  )}
                </UnitsList>
              </ListItem>
            ))}
          </UnitsList>
        );
      }}
    </RemoteData>
  );
};

const UnitsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

const UnitName = styled.h5`
  font-size: 1em;
  font-weight: bold;
  color: ${({ theme }) => theme.text.primaryColor};
`;

const ExternalLink = styled.a`
  && {
    color: ${({ theme }) => theme.text.secondaryColor};
    display: block;
    font-size: 0.875em;
    &:hover {
      color: ${({ theme }) => theme.secondaryColor};
      text-decoration: none;
    }

    &:focus:not(:active) {
      outline: ${({ theme }) => theme.focusOutline};
    }
  }
`;

const Link = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.text.secondaryColor};
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

export { OrganizationalUnits };
