import React from "react";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

import Label from "./Label";
import { RedStar } from "./RedStar";
import { TextField } from "./TextField";

import { TextFieldType } from "./types";

interface IProps {
  name: string;
  isRequired?: boolean;
  label?: string;
}

const NumberRangeField = ({ name, label, isRequired }: IProps) => {
  const numberFromName = `${name}-from`;
  const numberToName = `${name}-to`;
  const { trigger, errors } = useFormContext();
  const hasError =
    Boolean(get(errors, numberFromName)) || Boolean(get(errors, numberToName));
  return (
    <>
      {label && (
        <Label htmlFor={name} hasError={hasError}>
          {label} {isRequired ? <RedStar /> : null}
        </Label>
      )}
      <TextField
        name={numberFromName}
        label="Od"
        type={TextFieldType.NUMBER}
        isRequired={false}
        onChange={() => trigger(numberToName)}
      />
      <TextField
        name={numberToName}
        label="Do"
        type={TextFieldType.NUMBER}
        isRequired={false}
        onChange={() => trigger(numberFromName)}
      />
    </>
  );
};

export { NumberRangeField };
