import React from "react";
import styled from "styled-components";

import { withTranslate } from "./../../translate";

interface IProps {
  ariaControls: string;
  title: string;
  onClick: () => void;
  isOpen: boolean;
  withCollapse: boolean;
}

const Heading = ({
  ariaControls,
  title,
  onClick,
  isOpen,
  withCollapse
}: IProps) => {
  const iconClassName = `fa fa-chevron-${isOpen ? "up" : "down"}`;
  return (
    <HeadingContainer
      aria-controls={ariaControls}
      aria-expanded={isOpen}
      onClick={onClick}
      withCollapse={withCollapse}
    >
      {withCollapse && <ArrowIcon className={iconClassName} />}
      <Title>{title}</Title>
    </HeadingContainer>
  );
};

const HeadingContainer = styled.div`
  background: ${({ theme }) => theme.lightPrimaryColor};
  width: 100%;
  min-height: 55px;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.dividerColor};
  &:hover {
    cursor: ${({ withCollapse }) => (withCollapse ? "pointer" : "default")};
  }
`;

const ArrowIcon = styled.span`
  color: ${({ theme }) => theme.secondaryColor};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  margin-right: 12px;
  padding: 5px 6px 6px 7px;

  && {
    @media print {
      display: none;
    }
  }
`;

const Title = withTranslate(styled.span`
  color: ${({ theme }) => theme.text.primaryColor};
  font-size: 0.875em;
  font-weight: bold;
  text-transform: uppercase;
`);

export { Heading };
