import React from "react";

import { ContentColumn } from "../ContentColumn";
import { Row } from "../../TableComponents/MetricGrid";
import { ColumnWithTranslate } from "./ColumnWithTranslate";

interface IProps {
  unitName: string;
}

const UnitName = ({ unitName }: IProps) => {
  return (
    <Row>
      <ColumnWithTranslate head>
        Organization providing the information
      </ColumnWithTranslate>
      <ContentColumn>{unitName}</ContentColumn>
    </Row>
  );
};

export { UnitName };
