import React from "react";
import styled from "styled-components";
import { Controller } from "react-hook-form";

import { FieldPrototype } from "./FieldPrototype";

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  isRequired?: boolean;
  name: string;
}

const TextAreaField = ({
  isRequired = true,
  label,
  name,
  rows = 3,
  ...textAreaProps
}: IProps) => {
  return (
    <FieldPrototype isRequired={isRequired} label={label} name={name}>
      {({
        hasError,
        methods: {
          formState: { isSubmitting }
        }
      }) => (
        <Controller
          name={name}
          render={props => (
            <TextArea
              rows={rows}
              hasError={hasError}
              disabled={isSubmitting}
              {...textAreaProps}
              {...props}
            />
          )}
        />
      )}
    </FieldPrototype>
  );
};

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  font-size: 1em;
  color: ${({ theme }) => theme.text.primaryColor};
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.errorColor : theme.dividerColor};
  border-radius: 3px;
  background: ${({ theme }) => theme.defaultBackground};
  padding: 0.125rem 0.5rem;
`;

export { TextAreaField };
