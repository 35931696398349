import React from "react";

import { ContentColumn } from "../ContentColumn";
import { Row } from "../../TableComponents/MetricGrid";
import { ColumnWithTranslate } from "./ColumnWithTranslate";

interface IProps {
  views: number;
}

const VisitorNumber = ({ views }: IProps) => {
  return (
    <Row>
      <ColumnWithTranslate head>Number of visits</ColumnWithTranslate>
      <ContentColumn>{views}</ContentColumn>
    </Row>
  );
};

export { VisitorNumber };
