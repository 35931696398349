import React from "react";
import { useMappedState } from "redux-react-hook";

import { getImageSource } from "../../../utils/getImageSource";

import { Link } from "../Link";
import { Image } from "../Image";

interface IProps {
  logoMainHref: string;
  logoMainFileId: string;
  altImageTitle?: string;
  title?: string;
}

const CustomizedLogo = ({
  logoMainHref,
  logoMainFileId,
  altImageTitle = "Logo Biuletyn Informacji Publicznej",
  title = "Biuletyn Informacji Publicznej"
}: IProps) => {
  const { isContrast } = useMappedState(mapStateToProps);
  const logoUrl = getImageSource(logoMainFileId);
  return (
    <Link
      href={logoMainHref}
      id="logo"
      title={title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {isContrast ? title : <Image alt={altImageTitle} src={logoUrl} />}
    </Link>
  );
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

export { CustomizedLogo };
