import React, { useState } from "react";
import styled from "styled-components";

import Pagination from "../../pagination/Pagination";
import { createArticlePages } from "./utils/createArticlePages";

interface IProps {
  articleId: number;
  content: string;
  isHistorical: boolean;
  isPreview: boolean;
}

const Content = ({ articleId, content, isHistorical, isPreview }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const articlePages = createArticlePages(
    content,
    articleId,
    isHistorical,
    isPreview
  );

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div id="article-section">
        <ArticlePage>{articlePages[currentPage - 1]}</ArticlePage>
        {articlePages.length > 1 && (
          <Pagination
            total={articlePages.length}
            current={currentPage}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
};

const ArticlePage = styled.section`
  color: ${({ theme }) => theme.text.primaryColor};
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.text.secondaryColor};
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.secondaryColor};
    }
  }
`;

export { Content };
