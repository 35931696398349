import React from "react";
import { useMappedState } from "redux-react-hook";

import logoUrl from "../../../../assets/img/pks_logo.png";
import { Image } from "./Image";

const PksLogo = () => {
  const { isContrast } = useMappedState(mapStateToProps);
  const alt = "Logo Portalu Konsultacji Społecznych";
  const title = "Portal Konsultacji Społecznych";
  return isContrast ? <>title</> : <Image alt={alt} src={logoUrl} title={title} />;
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

export { PksLogo };
