import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

interface IProps {
  current: number;
  totalPages: number;
  onPageChange: (value: number) => void;
}

const Pages = ({ current, totalPages, onPageChange }: IProps) => {
  return (
    <PagesContainer>
      <ReactPaginate
        forcePage={current - 1}
        initialPage={current - 1}
        previousLabel={
          <span className={"fa fa-angle-left"} title={"Poprzednia strona"} />
        }
        nextLabel={
          <span className={"fa fa-angle-right"} title={"Następna strona"} />
        }
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={(data: { selected: number }) => {
          onPageChange(data.selected + 1);
        }}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </PagesContainer>
  );
};

const PagesContainer = styled.div`
  .pagination {
    width: fit-content;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    background: ${({ theme }) => theme.defaultBackground};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    li a {
      outline: none;
    }

    .active a {
      background: ${({ theme }) => theme.secondaryColor};
      color: ${({ theme }) => theme.defaultBackground};
      outline: initial;
    }

    li {
      margin: 0 0 0 -1px;
      border: 1px solid ${({ theme }) => theme.dividerColor};
      word-break: keep-all;
      width: 39px;
      height: 37px;

      a {
        color: ${({ theme }) => theme.text.primaryColor};
        display: inline-block;
        text-decoration: none;
        border: none;
        width: 39px;
        height: 37px;
        text-align: center;
        vertical-align: middle;
        line-height: 36px;
        position: relative;
        cursor: pointer;
        font-size: 0.875em;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }

        &:focus {
          z-index: 1;
        }

        &:first-child {
          border-top-left-radius: 1px;
          border-bottom-left-radius: 1px;
        }

        &:last-child {
          border-top-right-radius: 1px;
          border-bottom-right-radius: 1px;
        }
      }
    }
  }
`;

export { Pages };
