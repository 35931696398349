import { find } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ColumnField = ({ columnFields, articleFields }) => {
  return columnFields.map((columnField) => (
    <Column
      key={columnField.position}
      dangerouslySetInnerHTML={{
        __html: find(
          articleFields,
          articleField => articleField.fieldId === columnField.id
        )?.value
      }}
    />
  ));
};

ColumnField.propTypes = {
  columnFields: PropTypes.array,
  articleFields: PropTypes.array
};

ColumnField.defaultProps = {
  columnFields: [],
  articleFields: []
};

const Column = styled.span`
  img {
    max-width: 100px;
    height: auto;
  }

  a {
    color: ${({ theme }) => theme.text.secondaryColor};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.secondaryColor};
      text-decoration: none;
    }
  }
`;

export default ColumnField;
