import React from "react";
import { useDispatch, useMappedState } from "redux-react-hook";

import { toggleContrast } from "redux/modules/accessibility";

import {DesktopAccessibilityButton} from "./DesktopAccessibilityButton";

const ContrastButton = () => {
  const { isContrast } = useMappedState(mapState);
  const dispatch = useDispatch();

  return (
    <DesktopAccessibilityButton
      color="secondary"
      onClick={() => dispatch(toggleContrast())}
      title={isContrast ? "Wersja standardowa" : "Wersja kontrastowa"}
    >
      {isContrast ? (
        <>
          {/*eslint-disable-next-line*/}
          <span className="fa fa-eye-slash" accessKey={isContrast ? "K" : ""} />
        </>
      ) : (
        <>
          {/*eslint-disable-next-line*/}
          <span className="fa fa-eye" accessKey={isContrast ? "" : "K"} />
        </>
      )}
    </DesktopAccessibilityButton>
  );
};

const mapState = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

export { ContrastButton };
