import React from "react";

import {toggleWordsSpacing} from "utils/index";

import {DesktopAccessibilityButton} from "./DesktopAccessibilityButton";

const WordSpacingButton = () => {
  return (
    <DesktopAccessibilityButton
      aria-label="Odstęp między słowami"
      title="Odstęp między słowami"
      onClick={toggleWordsSpacing}
    >
      <span className="fa fa-arrows-h" /> <span className="fa fa-align-left" />
    </DesktopAccessibilityButton>
  );
};

export { WordSpacingButton };
