/**
 * Created by fkurzawa on 22.03.18.
 */
import React from "react";
import styled from "styled-components";

import { Section } from "./components/Section";

import { IArticlePlaceholder } from "../../../types/IArticlePlaceholder";

interface IProps {
  sections: IArticlePlaceholder[];
}

const Aside = ({ sections }: IProps) => (
  <AsideContainer>
    {sections.map(section => (
      <Section
        key={section.id}
        name={section.name}
        label={section.etiquette}
        content={section.content}
      />
    ))}
  </AsideContainer>
);

const AsideContainer = styled.aside`
  flex-basis: 250px;
  min-width: 250px;
`;

export { Aside };
