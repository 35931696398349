import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import TableRow from "./TableRow";
import ColumnField from "./ColumnField";
import TableCell from "./TableCell";
import { compose, withHandlers } from "recompose";
import { get } from "lodash";

const LinkRow = ({ article, columns, onNavigate }) => {
  const articleTitle = get(article, "aliasFields[0].value");

  return (
    <ClickableTableRow
      href={article.link}
      tabIndex="0"
      onClick={onNavigate}
      onKeyPress={onNavigate}
      aria-label={`Przejdź do artykułu: ${articleTitle}`}
    >
      {columns.map(column => (
        <Td key={column.id} width={column.width}>
          <ColumnField
            columnFields={column.columnFields}
            articleFields={article.columnFields}
          />
        </Td>
      ))}
    </ClickableTableRow>
  );
};

LinkRow.propTypes = {
  article: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
};

const Td = TableCell.withComponent("td");

const ClickableTableRow = styled(TableRow)`
  &:hover {
    background: ${({ theme }) => theme.lightPrimaryColor};
    cursor: pointer;
  }

  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

const composed = compose(
  withHandlers({
    onNavigate: ({ article, history }) => event => {
      if (event.type === "click" || event.key === "Enter") {
        history.push(`/${article.link}`);
      }
    }
  })
);

export default withRouter(composed(LinkRow));
