/**
 * Created by fkurzawa on 28.06.18.
 */
import { amber, white, grey, blueGrey, red, lightGreen } from "material-colors";

const primaryTheme = {
  primaryColor: lightGreen["500"],
  darkPrimaryColor: grey["700"],
  lightPrimaryColor: lightGreen["200"],
  secondaryColor: red["800"],
  dividerColor: grey["500"],
  errorColor: red["600"],
  highlightBackground: amber["100"],

  asideBackground: lightGreen["400"],
  asideMenuItemBackground: "transparent",

  defaultBackground: white,

  footerContentBackground: lightGreen["400"],
  footerLinkColor: blueGrey["800"],
  footerGoUpColor: blueGrey["600"],

  toolbarBackground: lightGreen["400"],
  focusOutline: "#ff8c1a solid 2px",
  text: {
    primaryColor: blueGrey["800"],
    secondaryColor: blueGrey["600"],
    lightColor: blueGrey["200"]
  },

  buttons: {
    primary: {
      color: grey["50"],
      background: red["500"],
      hoverBackground: red["900"]
    },

    secondary: {
      color: grey["50"],
      background: blueGrey["500"],
      hoverBackground: blueGrey["900"]
    },

    default: {
      color: lightGreen["900"],
      background: lightGreen["200"],
      hoverBackground: lightGreen["500"]
    },

    strongPrimary: {
      color: grey["50"],
      background: red["500"],
      hoverBackground: red["900"]
    },

    strongSecondary: {
      color: grey["50"],
      background: blueGrey["500"],
      hoverBackground: blueGrey["900"]
    }
  }
};

export default primaryTheme;
