import React from "react";

import { ColumnWithTranslate } from "./ColumnWithTranslate";
import { ContentColumn } from "../ContentColumn";
import { SpanWithTranslation } from "./SpanWithTranslation";
import { Row } from "../../TableComponents/MetricGrid";

interface IProps {
  createInformationDate: string;
}

const CreateInformationDate = ({ createInformationDate }: IProps) => {
  return (
    <Row>
      <ColumnWithTranslate head>
        Create real information date
      </ColumnWithTranslate>
      <ContentColumn>
        <SpanWithTranslation>{createInformationDate}</SpanWithTranslation>
      </ContentColumn>
    </Row>
  );
};

export { CreateInformationDate };
