/**
 * Created by fkurzawa on 16.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { UserTipRow } from "./UserTipRow";

const UserTip = ({ id, contact, description, email, phone }) => (
  <StyledTooltip id={id} effect="solid">
    <>
      {!contact && !description && !email && !phone && "Brak informacji"}
      {!!contact && <UserTipRow label="Kontakt" value={contact} />}
      {!!description && <UserTipRow label="Opis" value={description} />}
      {!!email && <UserTipRow label="Email" value={email} />}
      {!!phone && <UserTipRow label="Telefon" value={phone} />}
    </>
  </StyledTooltip>
);

UserTip.propTypes = {
  id: PropTypes.string.isRequired,
  contact: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string
};

const StyledTooltip = styled(ReactTooltip)`
  background-color: ${({ theme }) => theme.lightPrimaryColor} !important;
  color: ${({ theme }) => theme.text.primaryColor} !important;
  border: 1px solid ${({ theme }) => theme.dividerColor} !important;
  border-radius: 0 !important;
  opacity: 1 !important;
  padding: 9px 12px !important;
  max-width: 100vw;

  &:after {
    border: none !important;
  }
`;

export { UserTip };
