import React from 'react';

import {toggleLettersSpacing} from "utils/index";

import {DesktopAccessibilityButton} from "./DesktopAccessibilityButton";

const LettersSpacingButton = () => {
    return (
        <DesktopAccessibilityButton
            aria-label="Odstęp między literami"
            title="Odstęp między literami"
            onClick={toggleLettersSpacing}
        >
            <span className="fa fa-arrows-h" /> A
        </DesktopAccessibilityButton>
    );
};

export { LettersSpacingButton };