import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import AutoSuggest, {
  ChangeEvent,
  InputProps,
  SuggestionsFetchRequestedParams
} from "react-autosuggest";
import { useFormContext } from "react-hook-form";
import useRouter from "use-react-router";
import queryString from "query-string";

import { getSearchSuggestion } from "api/search-suggestion";

import { SuggestionInputField } from "./SuggestionInputField";

const SearchSuggestions = () => {
  const { getValues, setValue } = useFormContext();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const searchFieldName = "s";

  const onSuggestionsFetchRequested = async ({
    value
  }: SuggestionsFetchRequestedParams) => {
    const { elements } = await getSearchSuggestion(value);
    setSuggestions(elements || []);
  };
  const onSuggestionsClearRequested = () => setSuggestions([]);

  const { location } = useRouter<{
    s: string;
  }>();

  const { s } = queryString.parse(location.search);

  useEffect(() => {
    setValue("s", s?.toString() || "");
  }, [s, setValue]);

  const inputProps = {
    id: "skip-link-search",
    name: searchFieldName,
    onChange: (event: FormEvent<any>, { newValue }: ChangeEvent) => {
      setValue(searchFieldName, newValue);
    },
    placeholder: "Szukaj...",
    "aria-label": "Wyszukiwarka główna",
    value: getValues(searchFieldName) || ""
  };

  return (
    <StyledSuggestionsContainer>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionSelected={(event: FormEvent<any>, { suggestionValue }) => {
          setValue(suggestionValue);
        }}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={Suggestion}
        renderInputComponent={(inputProps: InputProps<string>) => {
          return <SuggestionInputField inputProps={inputProps} />;
        }}
        inputProps={inputProps}
        ref={node => {
          //[84903] Attribute role cause that NVDA rider was reading "Lista rozwijana" and that's why it has been removed
          return (
            node &&
            // @ts-ignore
            node.suggestionsContainer?.previousElementSibling?.offsetParent?.removeAttribute(
              "role"
            )
          );
        }}
      />
    </StyledSuggestionsContainer>
  );
};

const Suggestion = (suggestion: string) => suggestion;

const StyledSuggestionsContainer = styled.div`
  width: 100%;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    color: ${({ theme }) => theme.text.primaryColor};
    background: ${({ theme }) => theme.defaultBackground};
  }

  .react-autosuggest__suggestions-container {
    display: none;
    position: absolute;
    z-index: 2;
    border: 1px solid ${({ theme }) => theme.dividerColor};
    width: 100%;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: ${({ theme }) => theme.text.primaryColor};
    background: ${({ theme }) => theme.defaultBackground};
    font-size: 0.875em;

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background: ${({ theme }) => theme.lightPrimaryColor};
  }
`;

export { SearchSuggestions };
