import React, { ReactNode } from "react";
import styled from "styled-components";
// @ts-ignore
import { green, red, amber, grey } from "material-colors";
import { Notification } from "./Notification";

interface IProps {
  children: ReactNode;
  type?: Notification;
  onClose?: () => void;
}

const Alert = ({ children, type = Notification.DEFAULT, onClose }: IProps) => {
  return (
    <AlertContainer role={"alert"} type={type}>
      <Content>{children}</Content>

      {onClose && (
        <CloseButton
          data-dismiss="alert"
          aria-label="zamknij"
          onClick={onClose}
        >
          <span className={"fa fa-times"} />
        </CloseButton>
      )}
    </AlertContainer>
  );
};

const COLORS = {
  [Notification.SUCCESS]: {
    color: green["600"],
    background: green["50"]
  },
  [Notification.ERROR]: {
    color: red["600"],
    background: red["50"]
  },
  [Notification.WARNING]: {
    color: amber["600"],
    background: amber["50"]
  },
  [Notification.DEFAULT]: {
    color: grey["600"],
    background: grey["50"]
  }
};

const AlertContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  background: ${({ type }: { type: Notification }) => COLORS[type].background};
  border: 1px solid ${({ type }: { type: Notification }) => COLORS[type].color};
  color: ${({ type }: { type: Notification }) => COLORS[type].color};
  border-left-width: 5rem;
  display: flex;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 0.875em;
`;

const CloseButton = styled.button.attrs({
  type: "button"
})`
  background: transparent;
  font-size: 1.25rem;
  line-height: 1em;
  font-weight: bold;
  border: none;
  padding: 0.75rem 1rem;
  opacity: 0.375;
  align-self: flex-start;

  &:hover {
    cursor: pointer;
    opacity: 0.625;
  }
`;

export { Alert };
