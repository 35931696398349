import React from "react";

import {IChildrenProp} from "types/IChildrenProp";

import Button from "../../../../../buttons/Button";

interface IProps extends IChildrenProp{
  title: string;
  id: string;
}

const DropdownButton = ({ title, children, id }: IProps) => {
  return (
    <Button
      color="secondary"
      id={id}
      title={title}
    >
      <span style={{ display: "none" }}>Dropdown</span>
      {children}
    </Button>
  );
};

export { DropdownButton };
