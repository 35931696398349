import * as yup from "yup";

import { checkboxRequiredValidationSchema } from "utils/yup/checkboxRequiredValidationSchema";
import { recaptchaValidationSchema } from "utils/yup/recaptchaValidationSchema";
import { validationMessages } from "utils/yup/messages";

import { ICommentForm } from "../types/ICommentForm";

export const commentFormValidationSchema = yup.object<ICommentForm>().shape({
  comment: yup.string().required(validationMessages.requiredField),
  agreement: checkboxRequiredValidationSchema,
  "recaptcha-response": recaptchaValidationSchema
});
