/**
 * Created by fkurzawa on 21.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import NonArticleRelation from "./NonArticleRelation";

const RelationsNode = ({ name, relations }) => {
  return (
    <>
      <Name>{name}:</Name>
      <RelationsList>
        {relations.map(relation => (
          <Relation key={relation.id}>
            <ArrowIcon />
            {relation.link && (
              <Link to={`/${relation.link}`}>{relation.title}</Link>
            )}
            {!relation.link && <NonArticleRelation relation={relation} />}
          </Relation>
        ))}
      </RelationsList>
    </>
  );
};

RelationsNode.propTypes = {
  name: PropTypes.string.isRequired,
  relations: PropTypes.array
};

RelationsNode.defaultProps = {
  relations: []
};

const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.text.secondaryColor};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
    text-decoration: none;
  }
`;

const Name = styled.h5`
  margin: 0;
  padding: 0.375rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  line-height: 1em;
  font-size: 1em;
  color: ${({ theme }) => theme.text.secondaryColor};
`;

const RelationsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Relation = styled.li`
    display: flex;
    align-items: center;
    padding-left: 2rem;
    position: relative;

    a,
    button {
        cursor: pointer;
        display: inline-block;
        font-size: .875em;
        line-height: .875em;
        padding: .625em .375em;
        background: transparent;
        border: none;
        color: ${({ theme }) => theme.text.primaryColor}

        &:hover {
            text-decoration: none;
            color: ${({ theme }) => theme.secondaryColor}
        }
    }
`;

const ArrowIcon = styled.span.attrs({
  className: "fa fa-angle-double-right"
})`
  color: ${({ theme }) => theme.text.secondaryColor};
  margin-right: 0.75rem;
`;

export default RelationsNode;
