import React from "react";

import { setFontSize } from "utils/font-size";
import { FONT_SIZES } from "constants/font_sizes";

import {DesktopAccessibilityButton} from "./DesktopAccessibilityButton";

const MediumLettersButton = () => {
  return (
    <DesktopAccessibilityButton
      title="Duża wielkość liter"
      aria-label="Duża wielkość liter"
      onClick={() => setFontSize(FONT_SIZES.MEDIUM)}
    >
      A+
    </DesktopAccessibilityButton>
  );
};

export { MediumLettersButton };
