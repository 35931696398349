/**
 * Created by fkurzawa on 16.03.18.
 */
import React from "react";

import { IArticleRegistryListElement } from "modules/contentRegistry/types/IArticleRegistryListElement";

import TableRow from "./TableRow";
import { ResponsiveTable } from "../../TableComponents/ResponsiveTable";

interface IProps {
  showArticleEditReason: boolean;
  items: IArticleRegistryListElement[];
}

const Table = ({ showArticleEditReason, items }: IProps) => {
  return (
    <ResponsiveTable>
      <thead>
        <tr>
          <th>Czas</th>
          <th>Dane osoby zmieniającej</th>
          <th>Opis zmiany</th>
          {showArticleEditReason && <th>Powód zmiany</th>}
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <TableRow
            key={item.id}
            date={item.date}
            user={item.user}
            description={item.info}
            showReason={showArticleEditReason}
            reason={item.reason}
          />
        ))}
      </tbody>
    </ResponsiveTable>
  );
};

export { Table };
