/**
 * Created by fkurzawa on 23.03.18.
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Thumbnail = ({ image, onClick }) => {
  const [isImage, setIsImage] = useState(true);

  return (
    <>
      {isImage ? (
        <Image
          src={image.thumbnailSrc}
          alt={image.alt}
          title={image.caption}
          onError={() => setIsImage(false)}
          onClick={onClick}
        />
      ) : (
        <span>{image.alt}</span>
      )}
    </>
  );
};

Thumbnail.propTypes = {
  image: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

Thumbnail.defaultProps = {
  onClick: () => {}
};

const Image = styled.img`
  cursor: pointer;
`;

export { Thumbnail };
